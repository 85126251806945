import React from "react";
import NewAccount from "../components/auth-component/newAccount";
import Navbar from "../components/navbar";
import { Redirect } from "react-router-dom";

export default function SignUp() {
  const isAuth = localStorage.getItem("token");
  if (isAuth !== null) {
    return <Redirect to="/dashboard" />;
  } else
    return (
      <div className="bg-primary w-screen h-screen ">
        <Navbar />
        <div className="flex flex-row  bg-primary h-auto  justify-center ">
          <NewAccount />
        </div>
      </div>
    );
}
