// home.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "home",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    homeRequested: (home, action) => {
      home.loading = true;
    },
    homeRequestedFailed: (home, action) => {
      home.loading = false;
      home.errorMessage = action.payload;
    },
    homeFetched: (home, action) => {
      home.data = action.payload;
      home.loading = false;
      home.lastFetch = Date.now();
    },
  },
});

// Actions
export const { homeRequested, homeRequestedFailed, homeFetched } =
  slice.actions;

export default slice.reducer;

const url = "/v1/landing/config";

export const getHomeConfig = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      onStart: homeRequested.type,
      onSuccess: homeFetched.type,
      onError: homeRequestedFailed.type,
    })
  );
};
