import { useEffect, useState } from "react";
import PrimaryButton from "../components/buttons/primaryButton";
import { loadCart } from "../store/cart";
import Navbar from "../components/navbar";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {baseUrl, createAxiosInterceptor} from "../store/middleware/api";
import configureStore from "../store/configureStore";

const store = configureStore();

export default function Payment() {
  createAxiosInterceptor(store.dispatch);

  useEffect(() => {
    getSessionId();
    dispatch(loadCart());
  }, []);
  const auth = useSelector((state) => state.entities.currentUser);

  const cart = useSelector((state) => state.entities.cart);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  function getSessionId() {
    axios
      .request({
        baseURL: baseUrl,
        url: "v1/cart/transactionId",
        headers: {
          Authorization: auth.data.accessToken || "",
        },
      })
      .then((result) => {
        scriptLoaded(result.data);
      })
      .catch((error) => {});
  }

  function handleSubmit() {
    setIsLoading(true);
    window.myFatoorah
      .submit()
      .then(function (response) {
        axios
          .request({
            baseURL: baseUrl,
            url: "v1/cart/3dsSecurePage",
            headers: {
              Authorization: auth.data.accessToken || "",
            },
          })
          .then((result) => {
            setIsLoading(false);
            window.location = result.data;
          });
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }

  function scriptLoaded(sessionId) {
    var config = {
      countryCode: "KWT",
      sessionId: sessionId,
      cardViewId: "card-element",
      style: {
        direction: "rtl",
        cardHeight: 200,
        input: {
          color: "black",
          fontSize: "13px",
          fontFamily: "sans-serif",
          inputHeight: "32px",
          inputMargin: "16px",
          borderColor: "c7c7c7",
          borderWidth: "1px",
          borderRadius: "2px",
          boxShadow: "",
          placeHolder: {
            holderName: "الإسم المدون على البطاقة",
            cardNumber: "0000 0000 0000 0000",
            expiryDate: "MM/YY",
            securityCode: "CVV",
          },
        },
        label: {
          display: false,
          color: "black",
          fontSize: "13px",
          fontWeight: "normal",
          fontFamily: "sans-serif",
          text: {
            holderName: "Card Holder Name",
            cardNumber: "Card Number",
            expiryDate: "Expiry Date",
            securityCode: "Security Code",
          },
        },
        error: {
          borderColor: "red",
          borderRadius: "8px",
          boxShadow: "0px",
        },
      },
    };
    window.myFatoorah.init(config);
  }

  return (
    <div className="bg-primary min-h-screen w-screen">
      <Navbar />
      <main>
        <div className="mx-auto max-w-6xl py-6 sm:px-6  lg:px-8 bg-primary ">
          <div className="flex flex-col flex-grow md:flex-row md:justify-center h-screen">
            {/* Payment Details */}
            <div className="flex flex-col md:w-3/5  mx-4">
              <p className="text-white my-4">معلومات الدفع</p>
              <div className="flex flex-col bg-secondary justify-center items-center	w-full h-72 rounded-sm">
                <div className="md:w-3/5">
                  <p className="text-white mb-4 text-end">معلومات البطاقة:</p>
                </div>
                <div id="card-element" className="md:w-3/5"></div>
              </div>
            </div>
            <div className="flex flex-col md:w-2/5  mx-4 md:mt-0 mt-4">
              <div className="flex flex-row justify-between">
                <p className="text-white my-4">مجموع السلة</p>
                <a className="text-white my-4" href="/checkout">
                  (<u>تعديل</u>)
                </a>
              </div>
              <div>
                {/* TOTAL Amount */}
                <div className="flex flex-col rounded-sm bg-secondary ">
                  <div className="flex flex-row justify-between  py-2">
                    <p className="text-white p-4"> مجموع المنتجات</p>
                    <p className="text-white p-4">
                      {typeof cart.data.totals == "undefined"
                        ? ""
                        : cart.data.totals.totalAmount}{" "}
                      ريال
                    </p>
                  </div>
                  <hr className="border-1 border-gray-600" />

                  <hr className="border-1 border-gray-600" />

                  <div className="flex flex-row justify-between py-2">
                    <p className="text-primary-blue-color p-4">الإجمالي</p>
                    <p className="text-primary-blue-color p-4">
                      {typeof cart.data.totals == "undefined"
                        ? ""
                        : cart.data.totals.totalAmount}{" "}
                      ريال
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between py-2 my-4">
                <PrimaryButton
                  title="إتمام الطلب"
                  action={handleSubmit}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
