import Navbar from "../components/navbar";
import CardList from "../components/dashboard/cardList";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadResources } from "../store/resources";
import { loadClasses } from "../store/classes";
import Footer from "../components/home/footer";

export default function UserDashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClasses());
    dispatch(loadResources());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <div className="flex flex-col bg-primary min-h-screen w-screen  place-items-center ">
        <div className=" flex flex-col max-w-7xl ">
          <p className="text-primary-blue-color mt-2 md:mx-0 mx-4">
            أهلا بك, مجددا
          </p>
          <p className="text-white text-4xl font-bold mb-4 mt-2 md:mx-0 mx-4">
            لوحة التحكم
          </p>
          <p className="text-white mb-2 md:mx-0 mx-4">برامجي التدريبية:</p>
          <CardList />
        </div>
      </div>{" "}
      <div>
        <Footer color="bg-secondary" />
      </div>
    </div>
  );
}
