import { motion } from "framer-motion/dist/framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampAdvantages from "../components/home/campAdvantages";
import CampResult from "../components/home/campResult";
import CoachSection from "../components/home/coachSection";
import DateSection from "../components/home/dateSection";
import Footer from "../components/home/footer";
import HeroSection from "../components/home/hero-section";
import PromoVideo from "../components/home/promoVideo";
import WaitingListPopupDialog from "../components/home/waitingListPopup";

import WhoFor from "../components/home/WhoFor";

export default function LandingPageWithVideo() {
  const home = useSelector((state) => state.entities.home);
  const [isShown, setShow] = useState(false);
  const toggle = () => {
    setShow(!isShown);
  };

  if (home.loading) return <div></div>;
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ ease: "easeIn", duration: 0.75 }}
      className="relative w-full h-auto  transition-all ease-in-out duration-200"
    >
      {isShown ? <WaitingListPopupDialog toggle={toggle} /> : <div></div>}
      <HeroSection
        isRegistrationOpen={home.data.isRegistrationOpen}
        header={home.data.header}
        description={home.data.description}
        waitingList={toggle}
      />

      <PromoVideo
        promotionalVideos={home.data.promotionalVideos}
        isCoachVisible={home.data.isCoachInfoVisible}
      />
      {home.data.isCoachInfoVisible ? <CoachSection /> : <div></div>}
      <WhoFor />
      <CampAdvantages
        isRegistrationOpen={home.data.isRegistrationOpen}
        waitingList={toggle}
      />
      <CampResult />
      {/* <DateSection
        classStartDate={home.data.classStartDate}
        classEndDate={home.data.classEndDate}
        promotionalMessage={home.data.promotionalMessage}
      /> */}
      <Footer color="bg-black" />
    </motion.div>
  );
}
