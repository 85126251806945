import { combineReducers } from "redux";
import reducer from "./entities";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    entities: reducer,
  });
export default createRootReducer;
