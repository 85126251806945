import { Link } from "react-router-dom";
import ErrorAlert from "../Loaderes/errorAlert";
import { motion } from "framer-motion/dist/framer-motion";

const PaymentErrorBox = () => {
  return (
    <div className="flex flex-col bg-secondary py-8  md:px-20 px-8 mt-6 text-center mx-4 rounded-md items-center">
      {" "}
      <a href="/home" className="flex">
        <img className="p-4 w-28 h-24" src="assets/images/hrrir.png" />
      </a>
      <motion.img
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="p-4 w-28 h-28 "
        src="assets/images/close.png"
      />
      <div className="w-full md:w-80">
        <ErrorAlert message="حدث خطأ  في عملية الدفع" />
      </div>
      <div className="my-4">
        <Link to="/checkout">
          <p className="text-primary-blue-color text-xl">
            العودة إلى السلة
            <i className="fa fa-long-arrow-left ms-3"></i>{" "}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default PaymentErrorBox;
