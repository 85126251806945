import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const VideoListBox = () => {
  const selected =
    "flex flex-row p-4  transition duration-300 ease-in-out bg-hover-color border-s-8 border-primary-blue-color";
  const notSelected =
    "flex flex-row p-4  transition duration-300 ease-in-out hover:bg-hover-color ";
  const { id } = useParams();
  const { vid } = useParams();
  const currentCourse = useSelector((state) => state.entities.resources.data);

  let items = <div></div>;

  if (currentCourse.courses) {
    return (
      <div className="flex flex-col  md:mx-4 mx-8  h-72 md:h-4/5 bg-secondary overflow-y-auto">
        <div className=" rounded-md w-full ">
          <p className="text-white text-lg font-bold text-end w-full p-4">
            محتويات الدورة التدريبية
          </p>

          <ul>
            {
              (items = currentCourse.courses[id].lessons.map(
                (lesson, index) => {
                  const destination = "/" + id + "/videos/" + index;
                  return (
                    <Link to={destination} key={index}>
                      <li>
                        <div className={vid == index ? selected : notSelected}>
                          <img
                            src={
                              lesson.watched
                                ? "../../assets/images/checkResize.png"
                                : "../../assets/images/play.png"
                            }
                          />
                          <p className="text-white text-md font-bold text-end w-full ms-2">
                            {lesson.title}
                          </p>
                        </div>
                      </li>
                    </Link>
                  );
                }
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col  md:mx-4 mx-8 md:h-96 h-72 bg-secondary overflow-y-auto">
      {items}
    </div>
  );
};

export default VideoListBox;
