// Auth.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "auth",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
    SuccessMessage: "",
  },
  reducers: {
    authRequested: (currentUser, action) => {
      currentUser.loading = true;
      currentUser.isSuccess = false;
    },
    authRequestFailed: (currentUser, action) => {
      currentUser.loading = false;
      currentUser.SuccessMessage = "";
      currentUser.isFailed = true;
      currentUser.isSuccess = false;
      currentUser.errorMessage = action.payload;
    },
    authSuccess: (currentUser, action) => {
      currentUser.data = action.payload;
      localStorage.setItem("token", action.payload.refreshToken);
      localStorage.setItem("isAuth", true);
      currentUser.loading = false;
      currentUser.errorMessage = "";
      currentUser.isFailed = false;
      currentUser.isSuccess = true;
      sessionStorage.setItem("reloadCount", 1);

      currentUser.lastFetch = Date.now();
    },
    logout: (currentUser, action) => {
      currentUser.data = null;
      localStorage.removeItem("token");
      localStorage.setItem("isAuth", false);
    },
    forgotPassword: (currentUser, action) => {
      currentUser.loading = false;
      currentUser.errorMessage = "";
      currentUser.SuccessMessage = action.payload;
    },
    newPassword: (currentUser, action) => {
      currentUser.loading = false;
      currentUser.errorMessage = "";
    },
    refreshToken: (currentUser, action) => {
      currentUser.data = action.payload;
    },
    resetState: (currentUser, action) => {
      currentUser.loading = false;
      currentUser.SuccessMessage = "";
      currentUser.errorMessage = "";
      currentUser.isSuccess = false;
    },
  },
});

// Actions
export const {
  authRequested,
  authRequestFailed,
  authSuccess,
  newPassword,
  logout,
  refreshToken,
  forgotPassword,
  resetState,
} = slice.actions;

export default slice.reducer;

const registerUrl = "/v1/student/register";

// Action Creators
export const signUp = (userData) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: registerUrl,
      data: userData,
      onStart: authRequested.type,
      onSuccess: authSuccess.type,
      onError: authRequestFailed.type,
      method: "post",
      path: "/dashboard",
    })
  );
};

const loginUrl = "/v1/student/login";

export const login = (userData) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: loginUrl,
      data: userData,
      onStart: authRequested.type,
      onSuccess: authSuccess.type,
      onError: authRequestFailed.type,
      method: "post",
      path: "/dashboard",
    })
  );
};

const forgotPassUrl = "/v1/student/forgot-password";

export const forgotPasswords = (email) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: forgotPassUrl,
      params: { email: email },
      onStart: authRequested.type,
      onSuccess: forgotPassword.type,
      onError: authRequestFailed.type,
    })
  );
};

const resetPassword = "/v1/student/reset-password";

export const resetPasswords = (token, password) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resetPassword,
      path: "/login",
      data: {
        token: token,
        newPassword: password,
      },
      method: "patch",
      onStart: authRequested.type,
      onSuccess: newPassword.type,
      onError: authRequestFailed.type,
    })
  );
};
