import { useParams } from "react-router";
import { resetPasswords, resetState } from "../../store/auth";
import PrimaryButton from "../buttons/primaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { inputDecorator } from "../../utils/styleHelper";
import { Link } from "react-router-dom";

const NewPasswordBox = () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);

  const { id } = useParams();
  const {
    register,
    watch,
    handleSubmit,

    formState: { errors },
  } = useForm({});

  useEffect(() => {
    dispatch({ type: resetState.type });
  }, []);
  const auth = useSelector((state) => state.entities.currentUser);
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    dispatch(resetPasswords(id, data.password));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col bg-secondary py-8  md:px-20 px-8 mt-6 text-center mx-4 rounded-md items-center">
        <div>
          <Link to="/">
            <img className="p-4 w-28 h-24" src="../assets/images/hrrir.png" />
          </Link>
        </div>
        <p className="text-white mb-8 w-full md:w-80 ">
          ادخل كلمة المرور الجديدة
        </p>
        <div className="relative w-full">
          <input
            className={
              typeof errors.passwordConfirm == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            name="password"
            type={isShow ? "text" : "password"}
            placeholder="كلمة المرور الجديدة"
            {...register("password", {
              required: true,
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?_\-&]{8,}$/,
            })}
          />
          <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-3 mb-8 md:mb-5">
            <a
              href=""
              onClick={(e) => {
                setIsShow(!isShow);
                e.preventDefault();
              }}
            >
              <i
                className={
                  isShow
                    ? "fa fa-eye-slash text-white text-lg"
                    : "fa fa-eye text-white text-lg"
                }
                aria-hidden="true"
              />
            </a>
          </div>
          {errors.password && errors.password.type === "pattern" ? (
            <p className="text-red-600 text-xs">
              يجب أن تكون كلمة المرور 8 خانات على الأقل و تحتوي على حرف كبير
              ورقم
            </p>
          ) : (
            <br />
          )}
        </div>

        <div className="w-full relative">
          <input
            className={
              typeof errors.passwordConfirm == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            name="passwordConfirm"
            type={isShow ? "text" : "password"}
            placeholder="تأكيد كلمة المرور الجديدة"
            {...register("passwordConfirm", {
              required: true,
              validate: (value) => value === password.current,
            })}
          />
          <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-3">
            <a
              href=""
              onClick={(e) => {
                setIsShow(!isShow);
                e.preventDefault();
              }}
            >
              <i
                className={
                  isShow
                    ? "fa fa-eye-slash text-white text-lg"
                    : "fa fa-eye text-white text-lg"
                }
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <div className="my-2 w-full">
          <PrimaryButton
            title="إستعادة كلمة المرور"
            isLoading={auth.isLoading}
          />
        </div>
      </div>
    </form>
  );
};

export default NewPasswordBox;
