import React from "react";
import { useSelector } from "react-redux";
import ItemCell from "./itemCell";

const ItemList = () => {
  const products = useSelector((state) => state.entities.cart.data.products);

  let items = (
    <div className="border border-blue-700 shadow rounded-md p-4 w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-blue-400 h-12 w-12 me-4"></div>
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-blue-400 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-blue-400 rounded"></div>
            <div className="h-4 bg-blue-400 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
  if (products) {
    items = products.map((product) => {
      return <ItemCell key={product.description} item={product} />;
    });
  }

  return <div> {items}</div>;
};

export default ItemList;
