import { combineReducers } from "redux";
import cart from "./cart";
import auth from "./auth";
import coupon from "./coupon";
import resources from "./resources";
import classes from "./classes";
import profile from "./profile";
import home from "./home";
import waitingList from "./waitinglist";

export default combineReducers({
  cart: cart,
  currentUser: auth,
  coupon: coupon,
  resources: resources,
  classes: classes,
  profile: profile,
  home: home,
  waitingList: waitingList,
});
