import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

const CampResult = () => {
  return (
    <div className="bg-primary w-full text-white">
      <div className="mx-auto max-w-7xl md:p-16  bg-primary  py-12">
        <motion.div
          layout
          className="flex flex-col md:flex-row  justify-center md:space-y-0 space-y-8 "
        >
          <motion.div className="flex flex-col justify-center font-semibold md:text-3xl items-center h-full md:me-12">
            <motion.p
              viewport={{ once: true }}
              className="md:block hidden "
              initial={{ y: 70, opacity: 0.125 }}
              whileInView={{ y: 0, opacity: 0 }}
              transition={{
                duration: 1,
                delay: 0.25,
              }}
            >
              في النهاية ستكون قادراَ على{" "}
            </motion.p>

            <motion.p
              viewport={{ once: true }}
              className="md:block hidden"
              initial={{ y: 35, opacity: 0.25 }}
              transition={{
                duration: 1,
                delay: 0.25,
              }}
              whileInView={{ y: 0, opacity: 0 }}
            >
              في النهاية ستكون قادراَ على{" "}
            </motion.p>
            <motion.p className="  md:text-3xl text-2xl">
              في النهاية ستكون قادراَ على{" "}
            </motion.p>
            <motion.p
              className="md:block hidden"
              viewport={{ once: true }}
              initial={{ y: -35, opacity: 0.25 }}
              transition={{
                duration: 1,
                delay: 0.25,
              }}
              whileInView={{ y: -0, opacity: 0 }}
            >
              في النهاية ستكون قادراَ على{" "}
            </motion.p>
            <motion.p
              viewport={{ once: true }}
              className="md:block hidden"
              initial={{ y: -70, opacity: 0.125 }}
              transition={{
                duration: 1,
                repeatType: "reverse",
                delay: 0.25,
              }}
              whileInView={{ y: -0, opacity: 0 }}
            >
              في النهاية ستكون قادراَ على{" "}
            </motion.p>
          </motion.div>
          <div className="flex flex-col md:space-y-6 space-y-3 md:text-xl text-sm font-bold  justify-center items-center md:px-0 px-14">
            <motion.div
              viewport={{ once: true }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1, delay: 0.75 }}
              whileInView={{ opacity: 1 }}
              className="flex flex-row w-full justify-start"
            >
              <img
                className="md:me-4 me-3 md:w-7 md:h-7 w-5 h-5"
                src="assets/images/star.png "
                alt=""
              />
              <p className="">تعلم المونتاج من الصفر الى الاحتراف</p>
            </motion.div>

            <motion.div
              viewport={{ once: true }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1, delay: 1 }}
              whileInView={{ opacity: 1 }}
              className="flex flex-row w-full justify-start"
            >
              <img
                className="md:me-4 me-3 md:w-7 md:h-7 w-5 h-5"
                src="assets/images/star.png "
                alt=""
              />
              <p className="text-end">مشاركة أعمالك في مجتمع المحررين</p>
            </motion.div>
            <motion.div
              viewport={{ once: true }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1, delay: 1.25 }}
              whileInView={{ opacity: 1 }}
              className="flex flex-row w-full justify-start "
            >
              <img
                className="md:me-4 me-3 md:w-7 md:h-7 w-5 h-5"
                src="assets/images/star.png "
                alt=""
              />
              <p className="text-end md:block hidden ">
                الدخول للقاءات المباشرة الخاصة بالمشاركين في رحلة المونتاج{" "}
              </p>

              <p className="text-end md:hidden block">
                حضور اللقاءات المباشرة الخاصة بالرحلة{" "}
              </p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CampResult;
