import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";
import { useHistory, Link } from "react-router-dom";

const HomeNavbar = () => {
  const history = useHistory();
  const isAuth = localStorage.getItem("token");
  const dispatch = useDispatch();
  return (
    <nav className=" inset-x-0 top-0  justify-between z-40 text-white bg-transparent ">
      <div className="max-w-7xl mx-auto lg:px-16 md:px-10 px-6 py-6">
        <div className="flex justify-between items-center">
          <div className="flex space-x-7">
            <div>
              <Link to="/">
                <img
                  className="w-14 h-14"
                  src="../assets/images/hrrir-white.png"
                />
              </Link>
            </div>
          </div>
          <div>
            {" "}
            {isAuth == null ? (
              <a href="/login">
                <button
                  type="button"
                  className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 "
                >
                  تسجيل الدخول
                </button>
              </a>
            ) : (
              <div className="space-">
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 "
                  >
                    لوحة التحكم
                  </button>
                </Link>
                <button
                  onClick={() => {
                    dispatch(logout());
                    history.push("/");
                  }}
                  type="button"
                  className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 ms-2 "
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HomeNavbar;
