import React from "react";

import Navbar from "../components/navbar";

export default function TermsAndConditions() {
  return (
    <div className="bg-primary w-screen min-h-screen   text-white text-sm">
      <Navbar />

      <main className="">
        <div className="mx-auto max-w-6xl py-6 sm:px-6  lg:px-8 bg-primary min-h-screen">
          <div className="flex flex-grow flex-col  md:mx-0 mx-8 ">
            <p className="mb-4 mt-2">الشروط والأحكام</p>
            <div className="w-full bg-secondary  p-8  ">
              <div>
                <p style={{ textAlign: "right" }}>الأحكام والشروط</p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>​​الموافقة على الشروط</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  تشكل شروط الاستخدام هذه اتفاقية ملزمة قانونًا بينك ، سواء
                  شخصيًا أو نيابة عن كيان ("أنت") و بـ Hrrir.com ("شركة" أو "
                  نحن " أو " نحن " أو " الخاصة بنا " ") ، فيما يتعلق بوصولك إلى
                  موقع Hrrir.com الإلكتروني واستخدامه بالإضافة إلى أي نموذج
                  وسائط آخر أو قناة وسائط أو موقع ويب للجوال أو تطبيق جوال مرتبط
                  به أو مرتبط به أو متصل به (يشار إليه إجمالاً بـ" الموقع ").
                  أنت توافق على أنه من خلال الوصول إلى الموقع ، تكون قد قرأت
                  وفهمت وتوافق على الالتزام بجميع شروط الاستخدام هذه. إذا كنت لا
                  توافق على جميع شروط الاستخدام هذه ، فأنت ممنوع صراحة من
                  استخدام الموقع ويجب عليك التوقف عن استخدامه على الفور.
                </p>
                <p style={{ textAlign: "right" }}>
                  الشروط والأحكام التكميلية أو المستندات التي قد يتم نشرها على
                  الموقع من وقت لآخر مدرجة بموجب هذا صراحةً هنا بالإشارة إليها.
                  نحتفظ بالحق ، وفقًا لتقديرنا الخاص ، في إجراء تغييرات أو
                  تعديلات على شروط الاستخدام هذه في أي وقت ولأي سبب. سننبهك بأي
                  تغييرات عن طريق تحديث تاريخ "آخر تحديث" لشروط الاستخدام هذه ،
                  وأنت تتنازل عن أي حق في تلقي إشعار محدد لكل تغيير من هذا
                  القبيل. تقع على عاتقك مسؤولية مراجعة شروط الاستخدام هذه بشكل
                  دوري للبقاء على اطلاع على التحديثات. ستخضع للتغييرات في أي
                  شروط استخدام معدلة من خلال استمرار استخدامك للموقع بعد تاريخ
                  نشر شروط الاستخدام المعدلة هذه ، وستعتبر أنك قد علمت بها
                  وقبلتها.&nbsp;&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  المعلومات المقدمة على الموقع ليست معدة للتوزيع أو الاستخدام من
                  قبل أي شخص أو كيان في أي دولة قضائية أو دولة يكون فيها هذا
                  التوزيع أو الاستخدام مخالفًا للقانون أو اللوائح أو من شأنه أن
                  يُخضعنا لأي شرط تسجيل داخل هذه الدولة القضائية. وبناءً على ذلك
                  ، فإن هؤلاء الأشخاص الذين يختارون الوصول إلى الموقع من مواقع
                  أخرى يفعلون ذلك بمبادرتهم الخاصة وهم وحدهم المسؤولون عن
                  الامتثال للقوانين المحلية ، إذا كانت القوانين المحلية قابلة
                  للتطبيق وإلى الحد الأقصى.&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>حقوق الملكية الفكرية</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  ما لم تتم الإشارة إلى خلاف ذلك ، فإن الموقع هو ملكية خاصة بنا
                  وجميع التعليمات البرمجية المصدر وقواعد البيانات والوظائف
                  والبرامج وتصميمات مواقع الويب والصوت والفيديو والنصوص والصور
                  الفوتوغرافية والرسومات الموجودة على الموقع (يشار إليها مجتمعة
                  باسم "المحتوى") والعلامات التجارية والخدمة العلامات والشعارات
                  الواردة فيها ("العلامات") مملوكة لنا أو خاضعة لسيطرتنا أو
                  مرخصة لنا ، يتم توفير المحتوى والعلامات على الموقع "كما هي"
                  لمعلوماتك واستخدامك الشخصي فقط. باستثناء ما هو منصوص عليه
                  صراحةً في شروط الاستخدام هذه ، لا يجوز نسخ أي جزء من الموقع أو
                  أي محتوى أو علامات ، أو إعادة إنتاجها ، أو تجميعها ، أو إعادة
                  نشرها ، أو تحميلها ، أو نشرها ، أو عرضها علنًا ، أو ترميزها ،
                  أو
                </p>
                <p style={{ textAlign: "right" }}>
                  شريطة أن تكون مؤهلاً لاستخدام الموقع ، يتم منحك ترخيصًا
                  محدودًا للوصول إلى الموقع واستخدامه وتنزيل أو طباعة نسخة من أي
                  جزء من المحتوى الذي حصلت على حق الوصول إليه بشكل صحيح فقط
                  لأغراضك الشخصية غير التجارية استعمال. نحتفظ بجميع الحقوق غير
                  الممنوحة لك صراحةً في الموقع والمحتوى والعلامات.
                </p>
                <p style={{ textAlign: "right" }}>
                  إقرارات المستخدم باستخدام الموقع ، فإنك تقر وتضمن ما يلي: (1)
                  لديك الأهلية القانونية وتوافق على الامتثال لشروط الاستخدام هذه
                  ؛ (2) لست قاصرًا في الدولة التي تقيم فيها ؛ (3) لن تتمكن من
                  الوصول إلى الموقع من خلال وسائل آلية أو غير بشرية ، سواء من
                  خلال روبوت أو برنامج نصي أو غير ذلك ؛ (4) لن تستخدم الموقع لأي
                  غرض غير قانوني أو غير مصرح به ؛ و (5) لن ينتهك استخدامك للموقع
                  أي قانون أو لائحة معمول بها.
                </p>
                <p style={{ textAlign: "right" }}>
                  إذا قدمت أي معلومات غير صحيحة أو غير دقيقة أو غير حديثة أو غير
                  كاملة ، فيحق لنا تعليق حسابك أو إنهائه ورفض أي وجميع
                  الاستخدامات الحالية أو المستقبلية للموقع (أو أي جزء
                  منه).&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>الرسوم</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  والدفع نقبل طرق الدفع التالية: Visa و MasterCard و MADA.&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  قد يُطلب منك شراء أو دفع رسوم للوصول إلى بعض خدماتنا. أنت
                  توافق على تقديم معلومات الشراء والحساب الحالية والكاملة
                  والدقيقة لجميع عمليات الشراء التي تتم عبر الموقع. أنت توافق
                  أيضًا على تحديث معلومات الحساب والدفع على الفور ، بما في ذلك
                  عنوان البريد الإلكتروني وطريقة الدفع وتاريخ انتهاء صلاحية
                  بطاقة الدفع ، حتى نتمكن من إكمال معاملاتك والاتصال بك حسب
                  الحاجة. نقوم بفاتورتك من خلال حساب فوترة عبر الإنترنت لعمليات
                  الشراء التي تتم عبر الموقع. ستتم إضافة ضريبة المبيعات إلى سعر
                  المشتريات على النحو الذي نراه مطلوبًا. يجوز لنا تغيير الأسعار
                  في أي وقت. يجب أن تكون جميع المدفوعات بالريال السعودي.
                </p>
                <p style={{ textAlign: "right" }}>
                  أنت توافق على دفع جميع الرسوم أو الرسوم بالأسعار السارية وقتها
                  لمشترياتك ، وتفوضنا بتحصيل رسوم من مزود الدفع الذي اخترته
                  مقابل أي مبالغ من هذا القبيل عند الشراء.
                </p>
                <p style={{ textAlign: "right" }}>
                  نحتفظ بالحق في تصحيح أي أخطاء أو أخطاء في التسعير ، حتى لو كنا
                  قد طلبنا أو تلقينا الدفع بالفعل. نحتفظ أيضًا بالحق في رفض أي
                  طلب يتم تقديمه عبر الموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  إذا كانت لديك مشكلة تتعلق بمنتج مادي ، فيرجى الاتصال بـ
                  Hi@hrrir.com وسنعمل معك لتصحيح المشكلة.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>الأنشطة المحظورة</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  لا يجوز لك الوصول إلى الموقع أو استخدامه لأي غرض آخر غير ذلك
                  الذي نوفر الموقع من أجله. لا يجوز استخدام الموقع فيما يتعلق
                  بأي مساع تجارية باستثناء تلك التي تم اعتمادها أو الموافقة
                  عليها بشكل خاص من قبلنا.&nbsp;&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  بصفتك مستخدمًا للموقع ، فإنك توافق على عدم:
                </p>
                <p style={{ textAlign: "right" }}>
                  استرداد البيانات أو المحتوى الآخر بشكل منهجي من الموقع لإنشاء
                  أو تجميع ، بشكل مباشر أو غير مباشر ، مجموعة أو تجميع أو قاعدة
                  بيانات أو دليل دون إذن كتابي منا.
                </p>
                <p style={{ textAlign: "right" }}>
                  قم بأي استخدام غير مصرح به للموقع ، بما في ذلك جمع أسماء
                  المستخدمين و / أو عناوين البريد الإلكتروني للمستخدمين عن طريق
                  الوسائل الإلكترونية أو غيرها من الوسائل لغرض إرسال بريد
                  إلكتروني غير مرغوب فيه ، أو إنشاء حسابات مستخدمين بوسائل آلية
                  أو تحت ذرائع كاذبة.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدم وكيل شراء أو وكيل شراء لإجراء عمليات شراء على الموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدم الموقع للإعلان عن أو عرض بيع السلع والخدمات.
                </p>
                <p style={{ textAlign: "right" }}>
                  التحايل على ميزات الموقع المتعلقة بالأمان أو تعطيلها أو التدخل
                  فيها ، بما في ذلك الميزات التي تمنع أو تقيد استخدام أو نسخ أي
                  محتوى أو تفرض قيودًا على استخدام الموقع و / أو المحتوى المتضمن
                  فيه.
                </p>
                <p style={{ textAlign: "right" }}>
                  الانخراط في تأطير غير مصرح به أو ربطه بالموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  خداع أو تضليلنا نحن والمستخدمين الآخرين ، خاصة في أي محاولة
                  لمعرفة معلومات الحساب الحساسة مثل كلمات مرور المستخدم.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدم خدمات الدعم الخاصة بنا بشكل غير لائق أو أرسل تقارير
                  كاذبة عن إساءة المعاملة أو سوء السلوك.
                </p>
                <p style={{ textAlign: "right" }}>
                  الانخراط في أي استخدام آلي للنظام ، مثل استخدام البرامج النصية
                  لإرسال التعليقات أو الرسائل ، أو استخدام أي استخراج للبيانات ،
                  أو الروبوتات ، أو أدوات مماثلة لجمع البيانات واستخراجها.
                </p>
                <p style={{ textAlign: "right" }}>
                  التدخل في أو تعطيل أو إنشاء عبء لا داعي له على الموقع أو
                  الشبكات أو الخدمات المتصلة بالموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  محاولة انتحال شخصية مستخدم أو شخص آخر أو استخدام اسم مستخدم
                  لمستخدم آخر.
                </p>
                <p style={{ textAlign: "right" }}>
                  بيع أو نقل ملف التعريف الخاص بك.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدم أي معلومات تم الحصول عليها من الموقع من أجل مضايقة شخص
                  آخر أو الإساءة إليه أو إلحاق الضرر به.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدم الموقع كجزء من أي جهد للتنافس معنا أو استخدام الموقع و
                  / أو المحتوى لأي مسعى مدر للدخل أو مشروع تجاري.
                </p>
                <p style={{ textAlign: "right" }}>
                  فك أو عكس هندسة أي من البرامج التي تتألف منها أو تشكل جزءًا من
                  الموقع بأي شكل من الأشكال.
                </p>
                <p style={{ textAlign: "right" }}>
                  محاولة تجاوز أي إجراءات للموقع مصممة لمنع أو تقييد الوصول إلى
                  الموقع ، أو أي جزء منه.
                </p>
                <p style={{ textAlign: "right" }}>
                  مضايقة أو مضايقة أو تخويف أو تهديد أي من موظفينا أو وكلائنا
                  المشاركين في تقديم أي جزء من الموقع لك.
                </p>
                <p style={{ textAlign: "right" }}>
                  حذف حقوق الطبع والنشر أو إشعار حقوق الملكية الأخرى من أي
                  محتوى.
                </p>
                <p style={{ textAlign: "right" }}>
                  نسخ أو تعديل برنامج الموقع ، بما في ذلك على سبيل المثال لا
                  الحصر Flash أو PHP أو HTML أو JavaScript أو أي كود آخر.
                </p>
                <p style={{ textAlign: "right" }}>
                  تحميل أو نقل (أو محاولة تحميل أو نقل) فيروسات أو أحصنة طروادة
                  أو مواد أخرى ، بما في ذلك الاستخدام المفرط للأحرف الكبيرة
                  والبريد العشوائي (النشر المستمر للنص المتكرر) ، والذي يتعارض
                  مع استخدام أي طرف واستمتاعه بالموقع أو يعدل أو يضعف أو يعطل أو
                  يغير أو يتداخل مع استخدام أو ميزات أو وظائف أو تشغيل أو صيانة
                  الموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  تحميل أو نقل (أو محاولة تحميل أو نقل) أي مادة تعمل كآلية لجمع
                  أو نقل المعلومات السلبية أو النشطة ، بما في ذلك على سبيل
                  المثال لا الحصر ، تنسيقات تبادل الرسومات الواضحة ("gifs") ، 1
                  × 1 بكسل ، أخطاء الويب ، ملفات تعريف الارتباط ، أو أجهزة أخرى
                  مماثلة (يشار إليها أحيانًا باسم "برامج التجسس" أو "آليات
                  التجميع السلبي" أو "pcms").
                </p>
                <p style={{ textAlign: "right" }}>
                  باستثناء ما قد يكون نتيجة استخدام محرك البحث القياسي أو مستعرض
                  الإنترنت أو استخدام أو تشغيل أو تطوير أو توزيع أي نظام آلي ،
                  بما في ذلك على سبيل المثال لا الحصر ، أي عنكبوت أو روبوت أو
                  أداة غش أو قارئ غير متصل بالإنترنت يصل إلى الموقع ، أو استخدام
                  أو تشغيل أي برنامج نصي أو برنامج آخر غير مصرح به.
                </p>
                <p style={{ textAlign: "right" }}>
                  الاستخفاف أو التشويه أو الإضرار بطريقة أخرى ، في رأينا ، نحن و
                  / أو الموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  استخدام الموقع بطريقة تتعارض مع أي قوانين أو لوائح معمول بها.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>المساهمات التي يولدها المستخدم</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  لا يوفر الموقع للمستخدمين إرسال محتوى أو نشره. قد نوفر لك فرصة
                  إنشاء أو إرسال أو نشر أو عرض أو نقل أو أداء أو توزيع أو بث
                  المحتوى والمواد إلينا أو على الموقع ، بما في ذلك على سبيل
                  المثال لا الحصر النصوص والكتابات والفيديو والصوت والصور أو
                  رسومات أو تعليقات أو اقتراحات أو معلومات شخصية أو مواد أخرى
                  (يشار إليها مجتمعة باسم "المساهمات"). المساهمات قد تكون قابلة
                  للعرض من قبل المستخدمين الآخرين للموقع وعبر مواقع الطرف
                  الثالث. على هذا النحو ، قد يتم التعامل مع أي مساهمات ترسلها
                  وفقًا لسياسة خصوصية الموقع. عندما تنشئ أي مساهمات أو تتيحها ،
                  فإنك بذلك تقر وتضمن ما يلي:
                </p>
                <p style={{ textAlign: "right" }}>
                  1. لا ينتهك إنشاء مساهماتك أو توزيعها أو نقلها أو عرضها
                  للجمهور أو أدائها والوصول إليها أو تنزيلها أو نسخها حقوق
                  الملكية ، بما في ذلك على سبيل المثال لا الحصر حقوق الطبع
                  والنشر أو براءات الاختراع أو العلامة التجارية أو الأسرار
                  التجارية ، أو الحقوق المعنوية لأي طرف ثالث.
                </p>
                <p style={{ textAlign: "right" }}>
                  2. أنت منشئ ومالك أو لديك التراخيص والحقوق والموافقات
                  والإصدارات والأذونات اللازمة للاستخدام وتفويضنا نحن والموقع
                  والمستخدمين الآخرين للموقع باستخدام مساهماتك بأي طريقة يتوخاها
                  الموقع وشروط الاستخدام هذه.
                </p>
                <p style={{ textAlign: "right" }}>
                  3. لديك موافقة كتابية و / أو تصريح و / أو إذن من كل فرد يمكن
                  التعرف عليه في مساهماتك لاستخدام اسم أو شكل كل فرد يمكن التعرف
                  عليه لتمكين إدراج واستخدام مساهماتك بأي طريقة متوقعة بواسطة
                  الموقع وشروط الاستخدام هذه.
                </p>
                <p style={{ textAlign: "right" }}>
                  4. مساهماتك ليست خاطئة أو غير دقيقة أو مضللة.
                </p>
                <p style={{ textAlign: "right" }}>
                  5. مساهماتك ليست إعلانات غير مرغوب فيها أو غير مصرح بها أو
                  مواد ترويجية أو مخططات هرمية أو رسائل متسلسلة أو بريد عشوائي
                  أو رسائل بريدية جماعية أو أشكال أخرى من الالتماس.
                </p>
                <p style={{ textAlign: "right" }}>
                  6. مساهماتك ليست فاحشة ، بذيئة ، فاسقة ، قذرة ، عنيفة ، مضايقة
                  ، تشهيرية ، افترائية ، أو مرفوضة بأي شكل آخر (على النحو الذي
                  نحدده).
                </p>
                <p style={{ textAlign: "right" }}>
                  7. مساهماتك لا تسخر ، أو تحط من قدر ، أو تخيف ، أو تسيء إلى أي
                  شخص.
                </p>
                <p style={{ textAlign: "right" }}>
                  8. لا تدعو مساهماتك إلى الإطاحة العنيفة بأية حكومة أو تحرض أو
                  تشجع أو تهدد بإيذاء جسدي آخر.
                </p>
                <p style={{ textAlign: "right" }}>
                  9. لا تنتهك مساهماتك أي قانون أو لائحة أو قاعدة معمول بها.
                </p>
                <p style={{ textAlign: "right" }}>
                  10. لا تنتهك مساهماتك الخصوصية أو حقوق الدعاية لأي طرف ثالث.
                </p>
                <p style={{ textAlign: "right" }}>
                  11. لا تحتوي مساهماتك على أي مواد تطلب معلومات شخصية من أي شخص
                  يقل عمره عن 18 عامًا أو تستغل الأشخاص الذين تقل أعمارهم عن 18
                  عامًا بطريقة جنسية أو عنيفة.
                </p>
                <p style={{ textAlign: "right" }}>
                  12. لا تنتهك مساهماتك أي قانون معمول به يتعلق باستغلال الأطفال
                  في المواد الإباحية ، أو يقصد بها حماية صحة أو رفاهية القاصرين.
                </p>
                <p style={{ textAlign: "right" }}>
                  13. لا تتضمن مساهماتك أي تعليقات مسيئة مرتبطة بالعرق أو الأصل
                  القومي أو الجنس أو الميول الجنسية أو الإعاقة الجسدية.
                </p>
                <p style={{ textAlign: "right" }}>
                  14. لا تنتهك مساهماتك أو ترتبط بمواد تنتهك أي حكم من شروط
                  الاستخدام هذه أو أي قانون أو لائحة معمول بها.
                </p>
                <p style={{ textAlign: "right" }}>
                  أي استخدام للموقع ينتهك ما سبق ينتهك شروط الاستخدام هذه وقد
                  يؤدي ، من بين أمور أخرى ، إلى إنهاء أو تعليق حقوقك في استخدام
                  الموقع.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>ترخيص المساهمة</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  توافق أنت والموقع على أنه يجوز لنا الوصول إلى أي معلومات
                  وبيانات شخصية تقدمها وتخزينها ومعالجتها واستخدامها وفقًا لشروط
                  سياسة الخصوصية وخياراتك (بما في ذلك الإعدادات).
                </p>
                <p style={{ textAlign: "right" }}>
                  من خلال تقديم اقتراحات أو ملاحظات أخرى بخصوص الموقع ، فإنك
                  توافق على أنه يمكننا استخدام هذه التعليقات ومشاركتها لأي غرض
                  دون تعويض لك.
                </p>
                <p style={{ textAlign: "right" }}>
                  نحن لا نؤكد أي ملكية على مساهماتك. تحتفظ بالملكية الكاملة
                  لجميع مساهماتك وأي حقوق ملكية فكرية أو حقوق ملكية أخرى مرتبطة
                  بمساهماتك. نحن لسنا مسؤولين عن أي بيانات أو إقرارات في
                  مساهماتك التي قدمتها في أي منطقة على الموقع. أنت وحدك المسؤول
                  عن مساهماتك في الموقع وتوافق صراحة على إعفائنا من أي وكل
                  مسؤولية والامتناع عن أي إجراء قانوني ضدنا فيما يتعلق
                  بمساهماتك.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>التقديمات</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  أنت تقر وتوافق على أن أي أسئلة أو تعليقات أو اقتراحات أو أفكار
                  أو ملاحظات أو غيرها من المعلومات المتعلقة بالموقع ("عمليات
                  الإرسال") التي تقدمها لنا غير سرية وستصبح ملكنا الوحيد. سنمتلك
                  الحقوق الحصرية ، بما في ذلك جميع حقوق الملكية الفكرية ، ويحق
                  لنا استخدام ونشر هذه المواد المقدمة لأي غرض قانوني ، تجاري أو
                  غير ذلك ، دون إقرار أو تعويض لك. أنت تتنازل بموجب هذا عن جميع
                  الحقوق المعنوية لأي من هذه التقديمات ، وتتعهد بموجب هذا بأن أي
                  من هذه التقديمات أصلية معك أو أن لديك الحق في تقديم مثل هذه
                  التقديمات. أنت توافق على أنه لن يكون هناك أي طعن ضدنا بسبب أي
                  انتهاك مزعوم أو فعلي أو اختلاس لأي حق ملكية في عمليات الإرسال
                  الخاصة بك.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>إدارة الموقع</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  نحن نحتفظ بالحق ، ولكن ليس الالتزام ، في: (1) مراقبة الموقع
                  بحثًا عن انتهاكات شروط الاستخدام هذه ؛ (2) اتخاذ الإجراءات
                  القانونية المناسبة ضد أي شخص ، وفقًا لتقديرنا الخاص ، ينتهك
                  القانون أو شروط الاستخدام هذه ، بما في ذلك على سبيل المثال لا
                  الحصر ، إبلاغ سلطات إنفاذ القانون بهذا المستخدم ؛ (3) وفقًا
                  لتقديرنا الخاص وعلى سبيل المثال لا الحصر ، رفض أو تقييد الوصول
                  أو تقييد توفر أو تعطيل (إلى الحد الممكن تقنيًا) أي من مساهماتك
                  أو أي جزء منها ؛ (4) وفقًا لتقديرنا المطلق ودون قيود أو إشعار
                  أو مسؤولية ، لإزالة من الموقع أو تعطيل جميع الملفات والمحتويات
                  الزائدة في الحجم أو التي تشكل عبئًا بأي شكل من الأشكال على
                  أنظمتنا ؛
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>المدة والإنهاء</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  يجب أن تظل شروط الاستخدام هذه سارية المفعول والتأثير الكامل
                  أثناء استخدامك للموقع. بدون تقييد أي شرط آخر لشروط الاستخدام
                  هذه ، نحتفظ بالحق في رفض الوصول إلى الموقع واستخدامه (بما في
                  ذلك عناوين IP المعينة) لأي شخص أو لأي شخص آخر ، وفقًا لتقديرنا
                  الخاص ودون إشعار أو مسؤولية بدون سبب ، بما في ذلك على سبيل
                  المثال لا الحصر انتهاك أي تمثيل أو ضمان أو تعهد وارد في شروط
                  الاستخدام هذه أو أي قانون أو لائحة معمول بها. يجوز لنا إنهاء
                  استخدامك أو مشاركتك في الموقع أو حذف أي محتوى أو معلومات
                  نشرتها في أي وقت ، دون سابق إنذار ، وفقًا لتقديرنا
                  الخاص.&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  إذا قمنا بإنهاء حسابك أو تعليقه لأي سبب ، فيُحظر عليك التسجيل
                  وإنشاء حساب جديد باسمك أو اسم مزيف أو مستعار أو اسم أي طرف
                  ثالث ، حتى لو كنت تتصرف نيابة عن الطرف الثالث حفل. بالإضافة
                  إلى إنهاء حسابك أو تعليقه ، فإننا نحتفظ بالحق في اتخاذ
                  الإجراءات القانونية المناسبة ، بما في ذلك على سبيل المثال لا
                  الحصر ، السعي للحصول على تعويضات مدنية وجنائية وزجرية.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>التعديلات والانقطاعات</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  نحن نحتفظ بالحق في تغيير أو تعديل أو إزالة محتويات الموقع في
                  أي وقت أو لأي سبب وفقًا لتقديرنا الخاص دون إشعار. ومع ذلك ،
                  فإننا لسنا ملزمين بتحديث أي معلومات على موقعنا. نحتفظ أيضًا
                  بالحق في تعديل أو إيقاف الموقع بالكامل أو جزء منه دون إشعار في
                  أي وقت. لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث عن أي تعديل
                  أو تغيير في السعر أو تعليق أو وقف للموقع.&nbsp;&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  لا يمكننا ضمان أن الموقع سيكون متاحًا في جميع الأوقات. قد
                  نواجه مشاكل في الأجهزة أو البرامج أو غيرها من المشاكل أو نحتاج
                  إلى إجراء صيانة متعلقة بالموقع ، مما يؤدي إلى انقطاعات أو
                  تأخيرات أو أخطاء. نحتفظ بالحق في تغيير الموقع أو مراجعته أو
                  تحديثه أو تعليقه أو إيقافه أو تعديله بأي طريقة أخرى في أي وقت
                  أو لأي سبب دون إخطارك. أنت توافق على أننا لا نتحمل أي مسؤولية
                  من أي نوع عن أي خسارة أو ضرر أو إزعاج ناتج عن عدم قدرتك على
                  الوصول إلى الموقع أو استخدامه أثناء أي توقف أو توقف للموقع. لن
                  يتم تفسير أي شيء في شروط الاستخدام هذه على أنه يلزمنا بالحفاظ
                  على الموقع ودعمه أو توفير أي تصحيحات أو تحديثات أو إصدارات
                  فيما يتعلق بذلك.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>القانون الحاكم</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  في حال حدوث أي نزاع بين الطرفين لا سمح الله حول أي بند من بنود
                  الأحكام والشروط ، يحل النزاع ودياً عن طريق (الوساطة، التفاوض،
                  التسوية)، وفي حال تعثر الحل الودي خلال أسبوعين يحال النزاع الى
                  الجهة القضائية المختصة بمدينة الرياض.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>تسوية المنازعات</strong>
                </p>
                <p style={{ textAlign: "right" }}>المفاوضات غير الرسمية</p>
                <p style={{ textAlign: "right" }}>
                  للإسراع بالحل والتحكم في تكلفة أي نزاع أو خلاف أو مطالبة تتعلق
                  بشروط الاستخدام هذه (كل منها "نزاع" وبشكل جماعي ، "النزاعات")
                  التي تقدمها أنت أو نحن (بشكل فردي ، " الطرف "وبشكل جماعي ،"
                  الأطراف ") ، توافق الأطراف على المحاولة أولاً للتفاوض بشأن أي
                  نزاع (باستثناء تلك النزاعات المنصوص عليها صراحةً أدناه) بشكل
                  غير رسمي لمدة 30 يومًا على الأقل قبل بدء التحكيم. تبدأ هذه
                  المفاوضات غير الرسمية بناءً على إشعار خطي من أحد الطرفين إلى
                  الطرف الآخر.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>قيود</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  يتفق الطرفان على أن أي تحكيم يجب أن يقتصر على النزاع بين
                  الطرفين بشكل فردي. إلى أقصى حد يسمح به القانون ، (أ) لا يجوز
                  ضم أي تحكيم إلى أي إجراء آخر ؛ (ب) لا يوجد حق أو سلطة لأي نزاع
                  يتم التحكيم فيه على أساس الدعوى الجماعية أو لاستخدام إجراءات
                  الدعوى الجماعية ؛ و (ج) لا يوجد حق أو سلطة لأي نزاع يتم رفعه
                  بصفة تمثيلية مزعومة نيابة عن عامة الناس أو أي أشخاص آخرين.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>استثناءات من المفاوضات والتحكيم غير الرسمية</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  يتفق الطرفان على أن النزاعات التالية لا تخضع للأحكام المذكورة
                  أعلاه المتعلقة بالمفاوضات غير الرسمية والتحكيم الملزم: (أ) أي
                  نزاعات تسعى إلى إنفاذ أو حماية ، أو فيما يتعلق بصحة ، أي من
                  حقوق الملكية الفكرية لأحد الأطراف ؛ (ب) أي نزاع يتعلق أو ينشأ
                  عن مزاعم السرقة أو القرصنة أو التعدي على الخصوصية أو الاستخدام
                  غير المصرح به ؛ و (ج) أي مطالبة بأمر زجري. إذا تبين أن هذا
                  البند غير قانوني أو غير قابل للتنفيذ ، فلن يختار أي طرف
                  التحكيم في أي نزاع يقع ضمن هذا الجزء من هذا الحكم الذي يتبين
                  أنه غير قانوني أو غير قابل للتنفيذ ويتم الفصل في هذا النزاع من
                  قبل محكمة ذات اختصاص قضائي داخل المحاكم المدرجة لـ الاختصاص
                  القضائي أعلاه ، ويوافق الطرفان على الخضوع للاختصاص القضائي
                  الشخصي لتلك المحكمة.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>التصحيحات</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  قد تكون هناك معلومات على الموقع تحتوي على أخطاء مطبعية أو عدم
                  دقة أو سهو ، بما في ذلك الأوصاف والتسعير والتوافر والعديد من
                  المعلومات الأخرى. نحتفظ بالحق في تصحيح أي أخطاء أو عدم دقة أو
                  سهو وتغيير أو تحديث المعلومات الموجودة على الموقع في أي وقت
                  دون إشعار مسبق.
                </p>
                <p style={{ textAlign: "right" }}>التنازل</p>
                <p style={{ textAlign: "right" }}>
                  يتم توفير الموقع على أساس حالته وبحسب توفره. أنت توافق على أن
                  استخدامك للموقع وخدماتنا سيكون على مسؤوليتك وحدك. إلى أقصى حد
                  يسمح به القانون ، فإننا نخلي مسؤوليتنا عن جميع الضمانات ،
                  الصريحة أو الضمنية ، فيما يتعلق بالموقع واستخدامك له ، بما في
                  ذلك ، على سبيل المثال لا الحصر ، الضمانات الضمنية الخاصة
                  بالتسويق والملاءمة والصلاحية. لا نقدم أي ضمانات أو إقرارات
                  بشأن دقة أو اكتمال محتوى الموقع أو محتوى أي من مواقع الويب
                  المرتبطة بالموقع ولن نتحمل أي مسؤولية أو مسؤولية عن أي:
                </p>
                <p style={{ textAlign: "right" }}>
                  (1) أخطاء أو أخطاء أو معلومات .
                </p>
                <p style={{ textAlign: "right" }}>
                  (2) الإصابة الشخصية أو الأضرار بالممتلكات ، من أي طبيعة كانت ،
                  والتي تنتج عن وصولك إلى الموقع واستخدامه ،&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (3) أي وصول غير مصرح به إلى أو استخدام خوادمنا الآمنة و / أو
                  أي وجميع المعلومات الشخصية و / أو المعلومات المالية المخزنة
                  فيه ،&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (4) أي انقطاع أو توقف للإرسال إلى أو من الموقع ،&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (5) أي أخطاء ، الفيروسات أو أحصنة طروادة أو ما شابه ذلك قد يتم
                  نقله إلى الموقع أو من خلاله من قبل أي طرف ثالث و / أو (6) أي
                  أخطاء أو إغفالات في أي محتوى ومواد أو عن أي خسائر أو أضرار من
                  أي نوع. استخدام أي محتوى تم نشره أو نقله أو توفيره بطريقة أخرى
                  عبر الموقع.&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  نحن لا نضمن أو نصادق أو نضمن أو نتحمل المسؤولية عن أي منتج أو
                  خدمة يتم الإعلان عنها أو تقديمها من قبل طرف ثالث من خلال
                  الموقع أو أي موقع ويب مرتبط أو أي موقع ويب أو تطبيق جوال معلن
                  عنه أو معلن عنه أو في أي ولن نكون طرفًا في أو بأي طريقة كانت
                  مسؤولة عن مراقبة أي معاملة بينك وبين أي طرف ثالث من مقدمي
                  المنتجات أو الخدمات. كما هو الحال مع شراء منتج أو خدمة من خلال
                  أي وسيط أو في أي بيئة ، يجب عليك استخدام أفضل حكم لك وتوخي
                  الحذر حيثما كان ذلك مناسبًا.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>حدود المسؤولية</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  لن نتحمل نحن أو مديرينا أو موظفونا أو وكلائنا بأي حال من
                  الأحوال المسؤولية تجاهك أو تجاه أي طرف ثالث عن أي أضرار مباشرة
                  أو غير مباشرة أو تبعية أو نموذجية أو عرضية أو خاصة أو عقابية ،
                  بما في ذلك خسارة الأرباح أو الخسائر. أو غيرها من الأضرار
                  الناجمة عن استخدامك للموقع ، حتى لو تم إعلامنا بإمكانية حدوث
                  مثل هذه الأضرار. بصرف النظر عن أي شيء مخالف لما ورد في هذه
                  الوثيقة ، فإن مسؤوليتنا تجاهك عن أي سبب مهما كان شكل الدعوى ،
                  وبغض النظر عن شكل الدعوى ، ستقتصر في جميع الأوقات على أقل
                  المبلغ المدفوع ، إن وجد ، بواسطتك لنا أو لمدة 12 شهرًا . لا
                  تسمح بعض قوانين المملكة العربية السعودية والقوانين الدولية
                  بفرض قيود على الضمانات الضمنية أو استثناء أو تقييد بعض
                  الأضرار. إذا كانت هذه القوانين تنطبق عليك ، فقد لا تنطبق عليك
                  بعض أو كل إخلاء المسؤولية أو القيود المذكورة أعلاه ،
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>التعويض</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  أنت توافق على الدفاع عنا وتعويضنا وحمايتنا من أي ضرر ، بما في
                  ذلك الشركات التابعة لنا والشركات التابعة لنا وجميع مسؤولينا
                  ووكلائنا وشركائنا وموظفينا ، من وضد أي خسارة أو ضرر أو مسؤولية
                  أو مطالبة أو طلب ، بما في ذلك المحامون المعقولون الرسوم
                  والنفقات ، التي يتحملها أي طرف ثالث بسبب أو الناشئة عن:
                </p>
                <p style={{ textAlign: "right" }}>(1) استخدام الموقع ؛&nbsp;</p>
                <p style={{ textAlign: "right" }}>
                  (2) خرق شروط الاستخدام هذه ؛&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (3) أي خرق لإقراراتك وضماناتك المنصوص عليها في شروط الاستخدام
                  هذه ؛&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (4) انتهاكك لحقوق طرف ثالث ، بما في ذلك على سبيل المثال لا
                  الحصر حقوق الملكية الفكرية ؛ أو&nbsp;
                </p>
                <p style={{ textAlign: "right" }}>
                  (5) أي عمل ضار صريح تجاه أي مستخدم آخر للموقع تتصل به عبر
                  الموقع. بالرغم مما سبق ذكره ، فإننا نحتفظ بالحق ، على نفقتك
                  الخاصة ، في تولي الدفاع الحصري والتحكم في أي مسألة يطلب منك
                  تعويضنا بشأنها ، وأنت توافق على التعاون ، على نفقتك ، مع
                  دفاعنا عن هذه المطالبات. سنبذل جهودًا معقولة لإعلامك بأي
                  مطالبة أو إجراء أو إجراء يخضع لهذا التعويض بمجرد علمك به.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>بيانات المستخدم سنحتفظ</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  ببعض البيانات التي ترسلها إلى الموقع بغرض إدارة أداء الموقع ،
                  وكذلك البيانات المتعلقة باستخدامك للموقع. على الرغم من أننا
                  نقوم بإجراء نسخ احتياطية روتينية منتظمة للبيانات ، فأنت وحدك
                  المسؤول عن جميع البيانات التي ترسلها أو التي تتعلق بأي نشاط
                  قمت به باستخدام الموقع. أنت توافق على أننا لن نتحمل أي مسؤولية
                  تجاهك عن أي خسارة أو تلف في أي من هذه البيانات ، وأنت تتنازل
                  بموجب هذا عن أي حق في اتخاذ إجراء ضدنا ناشئ عن أي خسارة أو تلف
                  لهذه البيانات.
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>الاتصالات والمعاملات والتوقيعات الإلكترونية</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  تشكل زيارة الموقع وإرسال رسائل البريد الإلكتروني إلينا
                  واستكمال النماذج عبر الإنترنت اتصالات إلكترونية. أنت توافق على
                  تلقي الاتصالات الإلكترونية ، وتوافق على أن جميع الاتفاقيات
                  والإشعارات والإفصاحات وغيرها من الاتصالات التي نقدمها لك
                  إلكترونيًا ، عبر البريد الإلكتروني وعلى الموقع ، تفي بأي شرط
                  قانوني بأن يكون هذا الاتصال كتابيًا. أنت توافق بموجبه على
                  استخدام التوقيعات الإلكترونية والعقود والأوامر والسجلات الأخرى
                  ، وعلى التسليم الإلكتروني للإشعارات والسياسات وسجلات المعاملات
                  التي تم بدؤها أو إكمالها بواسطتنا أو عبر الموقع. أنت تتنازل
                  بموجب هذا عن أي حقوق أو متطلبات بموجب أي قوانين أو لوائح أو
                  قواعد أو مراسيم أو قوانين أخرى في أي دولة قضائية تتطلب توقيعًا
                  أصليًا أو تسليمًا أو الاحتفاظ بسجلات غير إلكترونية ،
                </p>
                <p style={{ textAlign: "right" }}>
                  <br></br>
                  <strong>متفرقات</strong>
                </p>
                <p style={{ textAlign: "right" }}>
                  تشكل شروط الاستخدام هذه وأي سياسات أو قواعد تشغيل ننشرها على
                  الموقع أو فيما يتعلق بالموقع مجمل الاتفاق والتفاهم بينك
                  وبيننا. إن فشلنا في ممارسة أو إنفاذ أي حق أو حكم من شروط
                  الاستخدام هذه لا يعتبر بمثابة تنازل عن هذا الحق أو الحكم. تعمل
                  شروط الاستخدام هذه إلى أقصى حد يسمح به القانون. يجوز لنا
                  التنازل عن أي من حقوقنا والتزاماتنا أو جميعها للآخرين في أي
                  وقت. لن نكون مسؤولين أو مسؤولين عن أي خسارة أو ضرر أو تأخير أو
                  فشل في التصرف بسبب أي سبب خارج عن سيطرتنا المعقولة. إذا تم
                  تحديد أي بند أو جزء من شرط من شروط الاستخدام هذه على أنه غير
                  قانوني أو باطل أو غير قابل للتنفيذ ، يعتبر هذا الحكم أو جزء
                  منه قابلاً للفصل عن شروط الاستخدام هذه ولا يؤثر على صلاحية
                  وإنفاذ أي أحكام متبقية. لا يوجد مشروع مشترك أو شراكة أو توظيف
                  أو علاقة وكالة تم إنشاؤها بينك وبيننا نتيجة لشروط الاستخدام أو
                  استخدام الموقع. أنت توافق على أن شروط الاستخدام هذه لن يتم
                  تفسيرها ضدنا بحكم صياغتها. أنت تتنازل بموجبه عن أي وجميع
                  الدفاعات التي قد تكون لديك بناءً على الشكل الإلكتروني لشروط
                  الاستخدام هذه وعدم توقيع الأطراف بهذه الاتفاقية لتنفيذ شروط
                  الاستخدام هذه. أنت توافق على أن شروط الاستخدام هذه لن يتم
                  تفسيرها ضدنا بحكم صياغتها. أنت تتنازل بموجبه عن أي وجميع
                  الدفاعات التي قد تكون لديك بناءً على الشكل الإلكتروني لشروط
                  الاستخدام هذه وعدم توقيع الأطراف بهذه الاتفاقية لتنفيذ شروط
                  الاستخدام هذه. أنت توافق على أن شروط الاستخدام هذه لن يتم
                  تفسيرها ضدنا بحكم صياغتها. أنت تتنازل بموجب هذا عن أي وجميع
                  الدفاعات التي قد تكون لديك بناءً على الشكل الإلكتروني لشروط
                  الاستخدام هذه وعدم توقيع الأطراف بهذه الاتفاقية لتنفيذ شروط
                  الاستخدام هذه.
                </p>
                <br />
                <p style={{ textAlign: "right" }}>
                  اتصل بنا لحل شكوى تتعلق بالموقع أو لتلقي مزيد من المعلومات
                  بخصوص استخدام الموقع ، يرجى الاتصال بنا على{" "}
                  <a href="mailto:Hi@hrrir.com" target="_blank" rel="noopener">
                    Hi@hrrir.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
