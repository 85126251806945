import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

const AdvantageCard = (props) => {
  return (
    <motion.div
      viewport={{ once: true }}
      initial={{ opacity: 0, x: props.x }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 1.5 }}
      className="flex flex-col p-8 space-y-2 bg-secondary max-w-xs items-center text-center"
    >
      <img className="w-48 h-48" src={props.img} alt="" />
      <p className="font-black">{props.title}</p>
      <p>{props.description}</p>
    </motion.div>
  );
};

export default AdvantageCard;
