import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

const AnimatedImage = (props) => {
  const rotation = props.rotation;
  const imgClass = "md:p-6 p-0 z-0 " + props.dim;
  return (
    <motion.div className={props.class}>
      <motion.img
        viewport={{ once: true }}
        whileInView={{ opacity: 1, y: 0, z: 0, rotate: rotation }}
        transition={{ duration: 1 }}
        initial={{ y: 40, opacity: 0 }}
        className={imgClass}
        src={props.src}
      />
    </motion.div>
  );
};

export default AnimatedImage;
//rotate: 180
