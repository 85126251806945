import Card from "./card";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

import CardLoadingIndicator from "./cardLoadingIndicator";
import { useState } from "react";
import PopupDialog from "../popup/popup";
import Vimeo from "@u-wave/react-vimeo";
import PrimaryButton from "../buttons/primaryButton";
import SecondaryButton from "../buttons/secondaryButton";

const CardList = () => {
  const classes = useSelector((state) => state.entities.classes);
  const courses = useSelector((state) => state.entities.resources.data.courses);
  const links = useSelector((state) => state.entities.resources.data.links);
  const [isShown, setShow] = useState(false);
  const toggle = () => {
    setShow(!isShown);
  };

  let items = [
    <CardLoadingIndicator key={0} />,
    <CardLoadingIndicator key={1} />,
    <CardLoadingIndicator key={2} />,
  ];
  if (courses && !classes.loading) {
    if (classes.data.length == 0 && courses.length == 0 && links.length == 0) {
      return (
        <div className="flex flex-row w-full mb-4">
          <div className="flex flex-col bg-secondary  p-8 max-w-5xl text-white md:mx-0 mx-4 w-full ">
            <p className="mb-3 text-center">
              اهلا بك في لوحة التحكم الخاصة بك، للانضمام لرحلة المونتاج، نتمنى
              اتباع الخطوات التالية:
            </p>
            <p className="text-center ">
              1-إختر الكمية 1 وإضغط على <b> التالي</b>
            </p>
            <div className="flex flex-col w-full h-auto items-center">
              <img src="assets/images/Step1.jpg" className="object-fil h-96" />
            </div>

            <p className="text-center mt-8">
              ٢- تعبئة البيانات ويجب أن تكون مطابقة مع بياناتك في موقع حرّر. ثم
              <b> اضغط تنفيذ الطلب</b>
            </p>
            <div className="flex flex-col w-full h-auto items-center mb-8">
              <img src="assets/images/Step2.jpg" className="object-fil h-96" />
            </div>
            <p className="text-center ">
              ٣- سوف يتم انشاء فاتورة لك، واختر الطريقة التي تريدها في الدفع ثم
              اضغط <b> ادفع الان</b>
            </p>
            <div className="flex flex-col w-full h-auto items-center">
              <img src="assets/images/Step3.jpg" className="object-fil h-96" />
            </div>
            {/* <Vimeo
              className="z-0 md:block hidden"
              responsive={true}
              video="https://vimeo.com/678656535"
            />
            <Vimeo
              className="z-0 md:hidden block"
              responsive={true}
              video="https://vimeo.com/678656492"
            /> */}
            <div className="w-full flex flex-col md:flex-row justify-evenly">
              <div className="w-full md:w-1/3 mt-4">
                <PrimaryButton
                  title="إضغط هنا للدفع"
                  loading={false}
                  action={() =>
                    window.open(
                      "https://sa.myfatoorah.com/Ar/SAU/ProductsCatalogue/ProductLink/050412113152845",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
            <br />
            <br />
            <p className="text-center block md:hidden">
              عند الدفع سيصلك في بريدك الالكتروني تأكيد الدفع خلال ٢٤ ساعة وسوف
              يتم تحديث صفحة لوحة التحكم الخاصة بك <br /> (قد يصلك في قائمة
              "الرسائل الترويجية")
              <br /> للاستفسارات والدعم:{" "}
              <a href="mailto:Hi@Hrrir.com" className="text-primary-blue-color">
                <br />
                Hi@Hrrir.com
              </a>
            </p>
            <p className="text-center hidden md:block">
              عند الدفع سيصلك في بريدك الالكتروني تأكيد الدفع خلال ٢٤ ساعة وسوف
              يتم تحديث صفحة لوحة التحكم الخاصة بك (قد يصلك الايميل في قائمة
              "الرسائل الترويجية") للاستفسارات والدعم:{" "}
              <a href="mailto:Hi@Hrrir.com" className="text-primary-blue-color">
                Hi@Hrrir.com
              </a>
            </p>
          </div>
        </div>
      );
    } else if (courses.length == 0 && links.length == 0) {
      return (
        <div className="grid xl:grid-cols-3 md:grid-cols-2  gap-8">
          <div className="col-span-3  bg-secondary  p-8 max-w-5xl text-white md:mx-0 mx-4">
            <p>
              مرحبا بك مجددا ، هذه لوحة التحكم التي سوف تكون بها المنتجات التي
              تم شرائها وسوف يتم تحديث هذه الصفحة عند انتهاء فترة التسجيل ولا
              تقلق سوف نرسل لك رسالة بالبريد الإلكتروني لتذكيرك.
            </p>
            <br />
            <br />
            <p className="text-left">فريق تطوير حرر</p>
          </div>
        </div>
      );
    }
    items = courses.map((course, index) => {
      return (
        <Card
          key={course.id}
          title={course.title}
          details={course.description}
          lessons={course.lessons}
          hasLessons={course.lessons.length > 0}
          toggle={toggle}
          url={course.image}
          index={index}
        />
      );
    });
    links.map((link, index) => {
      items.push(
        <Card
          key={index}
          title={link.title}
          details={link.description}
          url={link.image}
          href={link.url}
          hasLessons={false}
        />
      );
    });
  }

  return (
    <div className="grid xl:grid-cols-3 md:grid-cols-2  gap-8 mb-8">
      {isShown && <PopupDialog toggle={toggle} />}
      {items}
    </div>
  );
};

export default CardList;
