import React from "react";

const OutlineButton = (props) => {
  if (props.isLoading) {
    return (
      <button
        type="button text-white"
        className=" animate-pulse transition duration-500 ease-in-out bg-none hover:bg-primary-blue-color hover:text-white  border-solid border rounded-md text-primary-blue-color px-4 py-1 border-primary-blue-color"
        disabled
      >
        {props.title}
      </button>
    );
  } else {
    return (
      <button
        onClick={props.action}
        type="button"
        className="transition duration-500 ease-in-out bg-none hover:bg-primary-blue-color hover:text-white  border-solid border rounded-md text-primary-blue-color px-4 py-1 border-primary-blue-color"
      >
        {props.title}
      </button>
    );
  }
};

export default OutlineButton;
