import React, { useEffect } from "react";
import "./App.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { withRouter } from "react-router-dom";
import MainComponent from "./components/main";
import { getHomeConfig } from "./store/home";

const store = configureStore();

const App = () => {
  useEffect(() => {
    fakeRequest();
  }, []);

  const fakeRequest = () => {
    // if (Object.keys(home.data).length === 0 && isHome(window.location.pathname))
    store.dispatch(getHomeConfig());
  };
  return (
    <React.Fragment>
      <Provider store={store}>
        <MainComponent />
      </Provider>
    </React.Fragment>
  );
};

export default withRouter(App);
