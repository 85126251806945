import React from "react";
import SuccessAlert from "../Loaderes/successAlert";
import { Link } from "react-router-dom";

const PaymentSuccessBox = () => {
  return (
    <div className="flex flex-col bg-secondary py-8  md:px-20 px-8 mt-6 text-center mx-4 rounded-md items-center">
      {" "}
      <a href="/home" className="flex">
        <img className="p-4 w-28 h-24" src="assets/images/hrrir.png" />
      </a>
      <img className="p-4 w-28 h-28" src="assets/images/success.gif" />
      <SuccessAlert message="تم الدفع بنجاح " />
      <div className="my-4">
        <Link to="/dashboard">
          <p className="text-primary-blue-color text-xl">
            الذهاب إلى لوحة التحكم
            <i className="fa fa-long-arrow-left ms-3"></i>{" "}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccessBox;
