import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "libphonenumber-js";
import CountryCode from "./countrycode";
import CountryListComponent from "./countryList";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetState, signUp } from "../../store/auth";
import ErrorAlert from "../Loaderes/errorAlert";
import PrimaryButton from "../buttons/primaryButton";
import { inputDecorator, checkboxDecorator } from "../../utils/styleHelper";

const NewAccount = () => {
  const handleDigits = (v, n) => {
    setCountryPhoneCode(v);
    setCountryCode(n);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: resetState.type });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [countryCode, setCountryCode] = useState("SA");
  const [isShow, setIsShow] = useState(false);

  const [countryPhoneCode, setCountryPhoneCode] = useState("966");
  const onSubmit = (data) => {
    dispatch(signUp(data));
  };
  const auth = useSelector((state) => state.entities.currentUser);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center my-12">
        <p className="text-white text-xl">إنشاء الحساب</p>
        <div className="flex flex-col bg-secondary py-8  md:px-0 px-4 mt-6 text-center mx-6 rounded-md items-center ">
          <p className="text-white ">
            لديك حساب مسبقا؟
            <u className="text-primary-blue-color li">
              <Link to="/login">إضغط هنا لتسجيل الدخول</Link>
            </u>
          </p>
          {typeof auth.errorMessage.title == "undefined" ? (
            <div className="bg-secondary"></div>
          ) : (
            <div className="w-2/3">
              <ErrorAlert message={auth.errorMessage.detail} />
            </div>
          )}
          <div className="flex flex-row  w-full md:w-2/3 md:px-0 px-4 mt-4">
            <div className="flex flex-col w-1/2 me-2">
              <input
                className={
                  typeof errors.firstName == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="الإسم الأول*"
                {...register("firstName", { required: true })}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <input
                className={
                  typeof errors.lastName == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="text"
                placeholder="الإسم الأخير*"
                {...register("lastName", { required: true })}
              />
            </div>
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4">
            <input
              className={
                typeof errors.email == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type="email"
              placeholder="البريد الإلكتروني*"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
              })}
            />
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4">
            <CountryListComponent
              selected="SA"
              className="mb-4 "
              label="country"
              register={register}
              required
            />
          </div>

          <div className="w-full md:w-2/3 md:px-0 px-4">
            <input
              className={
                typeof errors.city == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type="text"
              placeholder="المدينة*"
              {...register("city", { required: true })}
            />
          </div>

          <div className="flex flex-row w-full md:w-2/3 md:px-0 px-4">
            <div className="w-2/3">
              <input
                className={
                  typeof errors.phoneNumber == "undefined"
                    ? inputDecorator(false)
                    : inputDecorator(true)
                }
                type="tel"
                placeholder="رقم الجوال*"
                {...register("phoneNumber", {
                  required: true,
                  validate: (v) => {
                    return isValidPhoneNumber(
                      countryPhoneCode + v,
                      countryCode
                    );
                  },
                })}
              />
            </div>

            <div className="flex flex-col w-1/3 ">
              <CountryCode
                onSelectCountry={handleDigits}
                label="countryCode"
                register={register}
                required
                className="appearance-none"
              />
            </div>
          </div>
          <div className="w-full md:w-2/3 md:px-0 px-4  my-2">
            <input
              className="bg-custom-g py-2  text-white rounded-sm w-full  border-custom-g"
              type="text"
              placeholder="حساب (Instagram - twitter - website)"
              {...register("account")}
            />
          </div>

          <div className="relative mb-4 w-full md:w-2/3 md:px-0 px-4 mt-2">
            <input
              className={
                typeof errors.password == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type={isShow ? "text" : "password"}
              placeholder="إنشاء كلمة المرور*"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*_\-#?&]{8,}$/,
              })}
            />
            <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-8 md:me-3 mb-7 md:mb-6">
              <a
                href=""
                onClick={(e) => {
                  setIsShow(!isShow);
                  e.preventDefault();
                }}
              >
                <i
                  className={
                    isShow
                      ? "fa fa-eye-slash text-white text-lg"
                      : "fa fa-eye text-white text-lg"
                  }
                  aria-hidden="true"
                />
              </a>
            </div>

            {errors.password && errors.password.type === "pattern" ? (
              <p className="text-red-600 text-xs">
                يجب أن تكون كلمة المرور 8 خانات على الأقل و تحتوي على حرف كبير
                ورقم
              </p>
            ) : (
              <div>
                {" "}
                <br />
              </div>
            )}
          </div>

          <div className="flex flex-row gap-2  w-2/3 items-center mb-4 ">
            <input
              id="offers"
              name="offers"
              type="checkbox"
              className={
                typeof errors.agree == "undefined"
                  ? checkboxDecorator(false)
                  : checkboxDecorator(true)
              }
              {...register("agree", { required: true })}
            />
            <label className="text-white md:text-base text-sm">
              {" "}
              أوافق على{" "}
              <u>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/TermsAndConditions"
                >
                  الشروط والاحكام
                </Link>
              </u>
            </label>
          </div>

          <div className=" mb-6 w-full md:w-2/3 md:px-0 px-4">
            <PrimaryButton title="إنشاء حساب" isLoading={auth.loading} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewAccount;
