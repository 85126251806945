// coupon.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "coupon",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    couponRequested: (coupon, action) => {
      coupon.loading = true;
    },
    couponRequestedFailed: (coupon, action) => {
      coupon.loading = false;
    },
    couponFetched: (coupon, action) => {
      coupon.data = action.payload;
      coupon.loading = false;
      coupon.lastFetch = Date.now();
    },
  },
});

// Actions
const { couponRequested, couponRequestedFailed, couponFetched } = slice.actions;

export default slice.reducer;

const url = "/v1/cart/coupon";

export const loadCoupon = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      onStart: couponRequested.type,
      onSuccess: couponFetched.type,
      onError: couponRequestedFailed.type,
    })
  );
};
