// profile.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "profile",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    profileRequested: (profile, action) => {
      profile.loading = true;
      profile.errorMessage = "";
      profile.successMessage = "";
    },
    profileRequestFailed: (profile, action) => {
      profile.loading = false;
      profile.isFailed = true;
      profile.errorMessage = action.payload;
      profile.successMessage = "";
    },
    profileRequestSuccess: (profile, action) => {
      profile.isFailed = false;
      profile.loading = false;
      profile.errorMessage = "";
      profile.data = action.payload;
      profile.lastFetch = Date.now();
    },
    profileUpdatedSucceeded: (profile, action) => {
      profile.isFailed = false;
      profile.loading = false;
      profile.errorMessage = "";
      profile.data = action.payload;
      profile.successMessage = "تم تحديث الملف الشخصي بنجاح";
      profile.lastFetch = Date.now();
    },
  },
});

// Actions
export const {
  profileRequested,
  profileRequestFailed,
  profileRequestSuccess,
  profileUpdatedSucceeded,
} = slice.actions;

export default slice.reducer;

const profileUrl = "/v1/student/profile";
// Action Creators
export const loadProfile = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: profileUrl,
      onStart: profileRequested.type,
      onSuccess: profileRequestSuccess.type,
      onError: profileRequestFailed.type,
    })
  );
};

export const updateProfile = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      data,
      url: profileUrl,
      onStart: profileRequested.type,
      onSuccess: profileUpdatedSucceeded.type,
      onError: profileRequestFailed.type,
      method: "Patch",
    })
  );
};
