import React from "react";

const CountryCode = ({ label, register, required, onSelectCountry }) => {
  return (
    <select
      onInput={(v) =>
        onSelectCountry(
          v.target.value,
          v.target[v.target.selectedIndex].getAttribute("data-countrycode")
        )
      }
      defaultValue="966"
      {...register(label, { required })}
      className="bg-custom-g text-white text-center me-2 my-2   border-custom-g "
      dir="ltr"
    >
      <option data-countrycode="IQ" value="964">
        +964
      </option>
      <option data-countrycode="EG" value="20">
        +20
      </option>

      <option data-countrycode="SA" value="966">
        +966
      </option>

      <option data-countrycode="YE" value="967">
        +967
      </option>
      <option data-countrycode="SI" value="963">
        +963
      </option>
      <option data-countrycode="DZ" value="213">
        +213
      </option>
      <option data-countrycode="MA" value="212">
        +212
      </option>
      <option data-countrycode="SD" value="249">
        +249
      </option>
      <option data-countrycode="QA" value="974">
        +974
      </option>
      <option data-countrycode="KW" value="965">
        +965
      </option>
      <option data-countrycode="BH" value="973">
        +973
      </option>
      <option data-countrycode="OM" value="968">
        +968
      </option>
      <option data-countrycode="JO" value="962">
        +962
      </option>
      <option data-countrycode="TN" value="216">
        +216
      </option>
      <option data-countrycode="LB" value="961">
        +961
      </option>
      <option data-countrycode="AE" value="971">
        +971
      </option>
      <option data-countrycode="PS" value="961">
        +970
      </option>
    </select>
  );
};

export default CountryCode;
