import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorAlert from "../Loaderes/errorAlert";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, navigationDone, resetState } from "../../store/auth";
import PrimaryButton from "../buttons/primaryButton";
import { inputDecorator } from "../../utils/styleHelper";

const LoginBox = () => {
  const auth = useSelector((state) => state.entities.currentUser);
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    dispatch({ type: resetState.type });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col bg-secondary py-8  md:px-20 px-8 md:mt-6 mb-16 text-center mx-4 rounded-sm items-center">
        <div>
          <Link to="/">
            <img className="p-4 w-28 h-24" src="assets/images/hrrir.png" />
          </Link>
        </div>
        <p className="text-white mb-8">سجل دخولك !</p>
        {typeof auth.errorMessage.title == "undefined" ? (
          <div className="bg-secondary"></div>
        ) : (
          <ErrorAlert message={auth.errorMessage.detail} />
        )}
        <div className="w-full">
          <input
            className={
              typeof errors.email == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="email"
            placeholder="البريد الإلكتروني"
            {...register("email", { required: true })}
          />
        </div>
        <div className="w-full relative">
          <input
            className={
              typeof errors.password == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type={isShow ? "text" : "password"}
            placeholder="كلمة المرور"
            {...register("password", { required: true })}
          />
          <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-3">
            <a
              href=""
              onClick={(e) => {
                setIsShow(!isShow);
                e.preventDefault();
              }}
            >
              <i
                className={
                  isShow
                    ? "fa fa-eye-slash text-white text-lg"
                    : "fa fa-eye text-white text-lg"
                }
                aria-hidden="true"
              />
            </a>
          </div>
        </div>

        <div className="my-4 w-full">
          <PrimaryButton title="  تسجيل الدخول" isLoading={auth.loading} />
        </div>

        <Link to="/resetPassword" className="mb-8">
          <u className="text-primary-blue-color ">نسيت كلمة المرور؟</u>
        </Link>
        <p className="text-white mb-2">
          لم تقم بشراء حزمتك بعد؟ أضغط{" "}
          <u>
            <Link to="/signup" className="text-primary-blue-color px-1">
              هنا
            </Link>
          </u>{" "}
          للشراء
        </p>
      </div>
    </form>
  );
};

export default LoginBox;
