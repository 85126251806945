import { ConnectedRouter } from "connected-react-router";
import ProtectedRoute from "../utils/ProtectedRoute";
import { Route, Switch, useLocation } from "react-router-dom";
import { history } from "../store/configureStore";

import routes from "../routes";
import LoadingIndicatorWithLogo from "./Loaderes/loadingIndicatorWithLogo";
import { useSelector } from "react-redux";

const MainComponent = () => {
  const home = useSelector((state) => state.entities.home);

  //   if (home.loading) {
  //     return <LoadingIndicatorWithLogo />;
  //   }
  return (
    <ConnectedRouter history={history}>
      <Switch>
        {routes.map((route, idx) => {
          if (route.isSecure) {
            return (
              <ProtectedRoute
                path={route.path}
                component={route.component}
                key={idx}
              />
            );
          } else {
            return (
              <Route path={route.path} component={route.component} key={idx} />
            );
          }
        })}
      </Switch>
    </ConnectedRouter>
  );
  //   }
};

export default MainComponent;
