import React from "react";

const ErrorAlert = (props) => {
  return (
    <div className=" bg-alert-error-color  text-red-600 p-1 w-full text-sm break-words text-center">
      <p className="">{props.message} </p>
    </div>
  );
};

export default ErrorAlert;
