// resources.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";
import { log } from "@craco/craco/lib/logger";

// Reducer
const slice = createSlice({
  name: "resources",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
  },
  reducers: {
    resourcesRequested: (resources, action) => {
      resources.loading = true;
    },
    resourcesRequestFailed: (resource, action) => {
      resource.loading = false;
      resource.isFailed = true;
      resource.errorMessage = action.payload;
    },
    resourcesRequestSuccess: (resources, action) => {
      resources.isFailed = false;
      resources.loading = false;
      resources.errorMessage = "";
      resources.data = action.payload;
      resources.lastFetch = Date.now();
    },
    markAsWatchedRequested: (resources, action) => {
      resources.loading = true;
    },
    markAsWatchedRequestFailed: (resource, action) => {
      resource.loading = false;
      resource.isFailed = true;
      resource.errorMessage = action.payload;
    },
    markAsWatchedRequestSuccess: (resources, action) => {
      resources.isFailed = false;
      resources.loading = false;
      resources.errorMessage = "";
      resources.data.courses = resources.data.courses.map((course) => {
        if (course.id === action.payload.id) {
          return action.payload;
        }
        return course;
      });
      resources.lastFetch = Date.now();
    },
  },
});

// Actions
export const {
  resourcesRequested,
  resourcesRequestFailed,
  resourcesRequestSuccess,
  markAsWatchedRequested,
  markAsWatchedRequestSuccess,
  markAsWatchedRequestFailed,
} = slice.actions;

export default slice.reducer;

const resourcesUrl = "/v1/student/resources";
// Action Creators
export const loadResources = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resourcesUrl,
      onStart: resourcesRequested.type,
      onSuccess: resourcesRequestSuccess.type,
      onError: resourcesRequestFailed.type,
    })
  );
};

export const markVideoAsWatched =
  (data, destination) => (dispatch, getState) => {
    dispatch(
      apiCallBegan({
        url: `${resourcesUrl}/courses/${data.courseId}`,
        onStart: markAsWatchedRequested.type,
        onSuccess: markAsWatchedRequestSuccess.type,
        onError: markAsWatchedRequestFailed.type,
        data: {
          lessonId: data.lessonId,
        },
        method: "patch",
        path: destination,
      })
    );
  };
