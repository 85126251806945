import React from "react";

const ItemCell = (props) => {
  return (
    <div className="flex flex-nowrap flex-row bg-secondary py-4 rounded-sm h-full px-4">
      <div className="flex w-20 h-20">
        <img src={props.item.image} alt="" />
      </div>
      <div className="flex-grow  h-16 mx-8">
        <p
          style={{ wordWrap: "break-word" }}
          className="flex h-full text-white items-center"
        >
          {props.item.description}
        </p>
      </div>
      <div className="flex text-white ">
        <p
          style={{ wordWrap: "break-word" }}
          className="flex h-full text-white items-center"
        >
          {props.item.amount} ريال
        </p>
      </div>
    </div>
  );
};

export default ItemCell;
