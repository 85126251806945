export function inputDecorator(isError) {
  if (isError) {
    return "bg-custom-g py-2 text-white rounded-sm me-2 border-1 border-red-600 my-2 focus:border-red-700 focus:ring-0 w-full placeholder-red-500";
  } else
    return "bg-custom-g py-2 text-white my-2 rounded-sm me-2 w-full border-custom-g ";
}

export function checkboxDecorator(isError) {
  if (isError) {
    return "h-4 w-4 text-primary-blue-color  rounded border-2 border-red-600 my-2 focus:border-red-700 focus:ring-0 ";
  } else
    return " h-4 w-4 text-primary-blue-color border-gray-300 rounded bg-secondary";
}
