import React from "react";
import PrimaryButton from "../buttons/primaryButton";
import AdvantageCard from "./advantageCard";
import { motion } from "framer-motion/dist/framer-motion";
import { useHistory, Link } from "react-router-dom";

const CampAdvantages = (props) => {
  let history = useHistory();

  return (
    <div className="flex flex-col bg-black w-full text-white overflow-hidden">
      <div className="flex flex-col mx-auto max-w-7xl md:px-32 md:py-20 sm:px-6 p-16 lg:px-8 bg-black items-center space-y-10">
        <motion.div
          viewport={{ once: true }}
          initial={{ opacity: 0, x: 30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="font-bold text-center text-2xl">مزايا رحلة المونتاج </p>
        </motion.div>
        <div className="grid md:grid-cols-2 place-items-center gap-12">
          <AdvantageCard
            x="240"
            title="المحافظة على النمو"
            description="ستمكنك رحلة المونتاج من بناء الفيديو بدءًا من الصفر وصولًا للكمال، وكذلك ستعمل الدروس الإضافية على تطوير مهاراتك واكتشاف أسلوبك .. وبالتالي خلق الطابع الخاص بك."
            img="assets/images/mazaya1.png"
          />
          <AdvantageCard
            x="-240"
            title="عالم المحررين"
            description="كن بالقرب من عالم التحرير والإنتاج وتواصل مع مجتمع إبداعي أساسه الشغف ونقطة مشاركته هي التفكير.
            وتأكد بأن المعرفة لا تأتي سوى من التجارب والخبرة والتعلّم المستمر."
            img="assets/images/mazaya2.png"
          />
        </div>
        <motion.div className="md:w-1/3 w-full">
          {props.isRegistrationOpen ? (
            <Link to="/signup">
              <PrimaryButton title="انضم الآن" />
            </Link>
          ) : (
            <PrimaryButton
              title="انضم الآن"
              action={() => {
                props.waitingList();
              }}
            />
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default CampAdvantages;
