import { motion } from "framer-motion/dist/framer-motion";
import ReactPlayer from "react-player";
import AnimatedImage from "./animatedImage";

const PromoVideo = (props) => {
  const borderType = props.isCoachVisible
    ? " borderNoBottom-52 border-2"
    : " border-52 border-24";
  return (
    <div className="bg-black w-full text-white text-center overflow-hidden md:h-auto ">
      <div className="mx-auto max-w-7xl md:px-20 sm:px-6 p-8 lg:px-8 bg-black relative h-full ">
        <div
          className={
            "absolute w-full h-full top-0 left-0 z-20   border-black opacity-90  filter blur-xl " +
            borderType
          }
        ></div>

        <div className="flex flex-col md:space-y-2 space-y-2 w-full mb-6 items-center  h-full md:justify-center justify-start">
          <div className="z-50 w-full">
            <div className="md:mb-0 -mb-7">
              <motion.p
                viewport={{ once: true }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                initial={{ opacity: 0 }}
                className=" text-center md:text-3xl text-2xl md:my-6 items-center  font-bold"
              >
                ماهي رحلة المونتاج؟
              </motion.p>{" "}
            </div>

            <motion.div
              viewport={{ once: true }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              initial={{ opacity: 0 }}
              className=" w-full  h-full "
            >
              <ReactPlayer
                controls={true}
                className=" w-full "
                width="100%"
                url={
                  props.promotionalVideos === undefined
                    ? ""
                    : props.promotionalVideos[0]
                }
              />
            </motion.div>
          </div>
        </div>
        <AnimatedImage
          rotation={0}
          src="assets/images/type.png"
          class="absolute top-0 md:top-16 left-0 filter blur-xl "
          dim="md:w-40 md:h-40 w-24 h-24"
        />

        <AnimatedImage
          rotation={0}
          src="assets/images/arrow.png"
          class="absolute md:top-1/2 md:left-24 left-1/4 bottom-20 filter blur-md "
          dim="md:w-32 md:h-32 w-14 h-14"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/pen.png"
          class="absolute   md:top-0  bottom-0 filter blur-md"
          dim="md:w-40 md:h-40 w-24 h-24"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/razor.png"
          class="absolute md:bottom-0 left-0 bottom-24 filter blur-xl"
          dim="md:w-40 md:h-40 w-14 h-14"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/flash.png"
          class="absolute  md:right-56  md:bottom-10  left-2/4 bottom-1/4 filter blur-ms "
          dim="md:w-20 md:h-20 w-10 h-10 "
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrow3.png"
          class=" absolute md:bottom-20  md:left-3/4 left-1/4  bottom-0  md:mb-11 ml-4 filter blur-md"
          dim="md:w-24 md:h-24 h-12 w-12"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrowone.png"
          class="absolute top-0 right-1/4 ml-4 filter blur-ms"
          dim="md:w-24 md:h-24 w-12 h-12"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/select.png"
          class="  absolute bottom-1/4 right-0  md:right-3/4 md:left-0  md:top-0  l filter blur-md"
          dim="md:w-28 md:h-28 w-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/inverted.png"
          class="absolute  left-0 bottom-1/2 md:left-3/4 ml-0 md:ml-16 filter blur-sm"
          dim="md:w-32 md:h-32 w-16 h-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrowcut.png"
          class="absolute   md:right-3/4  md:bottom-1/2 right-0 bottom-3/4 filter blur-xs"
          dim="md:w-20 md:h-20  w-12 h-12"
        />
        <AnimatedImage
          rotation={-60}
          src="assets/images/hand.png"
          class="absolute  md:right-0  right-1/3 bottom-3/4 md:bottom-4   filter blur-md"
          dim="md:w-36 md:h-36 h-16 w-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/invertedarrow.png"
          class="absolute  md:right-3/4  md:bottom-0  left-0 bottom-8 filter blur-ms"
          dim="md:w-24 md:h-24 w-12 h-12"
        />
      </div>
    </div>
  );
};

export default PromoVideo;
