import PrimaryButton from "../buttons/primaryButton";
import HomeNavbar from "../nav/homeNav";
import { Link } from "react-router-dom";

const HeroSection = (props) => {
  return (
    <div>
      <header className="relative flex-col flex items-center justify-center h-screen overflow-hidden ">
        <div className="absolute w-full h-full top-0 left-0 z-30  ">
          <HomeNavbar />
        </div>
        <div className="absolute w-full h-full top-0 left-0 z-20  bg-black opacity-60"></div>
        <div className="w-full z-30">
          <div className="mx-auto max-w-7xl px-6 z-30 lg:px-16  md:px-10">
            <div className="flex flex-row md:items-center  w-full   ">
              <div className="mb-8 md:px-0 px-0 md:p-0 md:space-y-6 space-y-4  md:w-3/4 w-full">
                <h1 className="text-2xl  text-white   md:text-4xl 2xl:text-5xl font-bold md:mt-64 lg:mt-32 mt-32 whitespace-pre-line">
                  {props.header}
                </h1>
                <p className=" text-white text-lg font-light md:text-xl whitespace-pre-line">
                  {props.description}
                </p>
                <div className=" md:w-1/5 w-1/2">
                  {props.isRegistrationOpen ? (
                    <Link to="/signup">
                      <PrimaryButton title="انضم الآن" />
                    </Link>
                  ) : (
                    <PrimaryButton
                      title="انضم الآن"
                      action={() => {
                        props.waitingList();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <video
          autoPlay
          playsInline
          loop
          muted
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none "
        >
          <source src="loop.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </header>
    </div>
  );
};

export default HeroSection;
