import { motion } from "framer-motion/dist/framer-motion";

const LoadingIndicator = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      className="flex flex-col w-full h-full justify-center items-center  transition-all ease-in-out duration-300"
    >
      <div className=" flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-white"></div>
      </div>
    </motion.div>
  );
};

export default LoadingIndicator;
