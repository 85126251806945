import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const script = document.createElement("script");
script.src =
  process.env.REACT_APP_HOST_ENV === "production"
    ? "https://sa.myfatoorah.com/cardview/v1/session.js"
    : "https://demo.myfatoorah.com/cardview/v1/session.js";
script.async = true;
document.body.appendChild(script);
