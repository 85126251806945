import React from "react";
import PaymentSuccessBox from "../components/payment/PaymentSuccessBox";

const PaymentSuccess = () => {
  return (
    <div className=" bg-account-image bg-primary h-screen bg-fixed overflow-hidden">
      <div className="flex flex-row w-full justify-center md:h-full md:items-center">
        <PaymentSuccessBox />
      </div>
    </div>
  );
};

export default PaymentSuccess;
