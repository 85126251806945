import React from "react";
import { getDateAsObject } from "../../utils/dateHelper";

const DateSection = (props) => {
  const date = getDateAsObject(props.classStartDate, props.classEndDate);
  if (date === undefined) return <div></div>;
  return (
    <div className="bg-milky-color w-full text-black text-center">
      <div className="mx-auto max-w-7xl md:px-20 sm:px-6 p-8 lg:px-8 bg-milky-color ">
        <div className="flex flex-col items-center md:space-y-2 space-y-4 w-full">
          <img className="w-24 h-24" src="assets/images/date.png" />
          <p className="text-xl ">
            {`يبدأ المعسكر من ${date.startDay}  ${date.startMonth} إلى ${date.endDay} ${date.endMonth} `}
            <br /> {`من الساعة ${date.startTime} الى ${date.endTime}`}
          </p>
          <p className="text-sm">{props.promotionalMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default DateSection;
