import { isValidNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../store/profile";
import { inputDecorator } from "../../utils/styleHelper";
import CountryCode from "../auth-component/countrycode";
import CountryListComponent from "../auth-component/countryList";
import PrimaryButton from "../buttons/primaryButton";
import ErrorAlert from "../Loaderes/errorAlert";
import SuccessAlert from "../Loaderes/successAlert";

const ProfileBox = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.entities.profile);
  const [isShow, setIsShow] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});

  useEffect(() => {
    setValue("firstName", profile.data.firstName);
    setValue("lastName", profile.data.lastName);
    setValue("email", profile.data.email);
    setValue("phoneNumber", profile.data.phoneNumber);
    setValue("country", profile.data.country);
    setValue("city", profile.data.city);
    setValue("account", profile.data.socialMediaAccounts);
  }, []);

  password.current = watch("password", "");

  const onSubmit = (data) => {
    dispatch(updateProfile(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full px-4">
        {profile.errorMessage === "" ? (
          <div></div>
        ) : (
          <ErrorAlert message={profile.errorMessage.title} />
        )}
        {profile.successMessage === "" ? (
          <div></div>
        ) : (
          <SuccessAlert message={profile.successMessage} />
        )}
      </div>
      <div className="flex md:flex-row flex-col  w-full ">
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>الإسم الأول</p>
          <input
            className={
              typeof errors.firstName == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="text"
            {...register("firstName", { required: true }, ["ffff"])}
          />
        </div>
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>الإسم الأخير</p>
          <input
            className={
              typeof errors.lastName == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="text"
            {...register("lastName", { required: true })}
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col  w-full">
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>الدولة</p>
          <CountryListComponent
            selected="IQ"
            className="mb-4 "
            label="country"
            register={register}
            required
          />
        </div>
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>المدينة</p>
          <input
            className={
              typeof errors.city == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="text"
            {...register("city", { required: true })}
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col  w-full">
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>رقم الجوال</p>
          <div className="flex flex-row">
            <input
              className={
                typeof errors.phoneNumber == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              type="tel"
              {...register("phoneNumber", {
                required: true,
                validate: (v) => {},
              })}
            />
            <CountryCode
              //   onSelectCountry={handleDigits}
              label="countryCode"
              register={register}
              required
              className="appearance-none"
            />
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col  w-full">
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>كلمة المرور الجديدة</p>

          <div className="relative">
            <input
              className={
                typeof errors.password == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              name="password"
              type={isShow ? "text" : "password"}
              placeholder="كلمة المرور الجديدة"
              {...register("password", {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?_\-&]{8,}$/,
              })}
            />
            <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-3">
              <a
                href=""
                onClick={(e) => {
                  setIsShow(!isShow);
                  e.preventDefault();
                }}
              >
                <i
                  className={
                    isShow
                      ? "fa fa-eye-slash text-white text-lg"
                      : "fa fa-eye text-white text-lg"
                  }
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>

          {errors.password && errors.password.type === "pattern" && (
            <p className="text-red-600 text-xs">
              يجب أن تكون كلمة المرور 8 خانات على الأقل و تحتوي على حرف كبير
              ورقم
            </p>
          )}
        </div>
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>تأكيد كلمة المرور الجديدة</p>
          <div className="relative">
            <input
              className={
                typeof errors.passwordConfirm == "undefined"
                  ? inputDecorator(false)
                  : inputDecorator(true)
              }
              name="passwordConfirm"
              type={isShow ? "text" : "password"}
              placeholder="تأكيد كلمة المرور الجديدة"
              {...register("passwordConfirm", {
                validate: (value) =>
                  password.current.length < 0 || value === password.current,
              })}
            />
            <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5 me-3">
              <a
                href=""
                onClick={(e) => {
                  setIsShow(!isShow);
                  e.preventDefault();
                }}
              >
                <i
                  className={
                    isShow
                      ? "fa fa-eye-slash text-white text-lg"
                      : "fa fa-eye text-white text-lg"
                  }
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col  w-full">
        <div className="flex flex-col md:w-2/5 w-full p-4">
          <p>حساب تواصل اجتماعي</p>
          <input
            className={inputDecorator(false)}
            type="text"
            placeholder="حساب (Instagram - twitter - website)"
            {...register("account")}
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col w-full  md:w-1/6">
        <div className="p-4 w-full">
          <PrimaryButton title="حفظ" isLoading={false} />
        </div>
      </div>
    </form>
  );
};

export default ProfileBox;
