import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadCart } from "../store/cart";
import { loadCoupon } from "../store/coupon";
import Navbar from "../components/navbar";
import Basket from "../components/Checkout-components/basket";
import TotalAmount from "../components/Checkout-components/totalAmount";
import ErrorAlert from "../components/Loaderes/errorAlert";

export default function Checkout() {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.entities.cart);
  const coupon = useSelector((state) => state.entities.coupon.data);

  useEffect(() => {
    dispatch(loadCart());
    dispatch(loadCoupon());
  }, []);

  return (
    <div className="bg-primary min-h-screen w-screen">
      <Navbar />
      {typeof coupon.code == "undefined" ? (
        <div className="bg-primary"></div>
      ) : (
        <div className="transition duration-500 ease-in opacity-100 flex bg-primary-blue-color p-2 justify-center w-full">
          <p className="transition duration-500 ease-in  flex h-full text-white items-center text-center">
            هلا نريد أن نجعل منتجنا في متناول الجميع,وندعوك لإستخدام الرمز
            الترويجي {coupon.code} للحصول على خصم
            {Math.floor(coupon.discountPercentage * 100)}% من سعر المنتج!
          </p>
        </div>
      )}

      <main>
        <div className="mx-auto max-w-6xl py-6 sm:px-6  lg:px-8 bg-primary ">
          {typeof cart.errorMessage.title == "undefined" ? (
            <div className="bg-primary"></div>
          ) : (
            <ErrorAlert message={cart.errorMessage.detail} />
          )}
          <div className="flex flex-col flex-grow md:flex-row md:justify-center h-screen">
            <Basket />
            <TotalAmount />
          </div>
        </div>
      </main>
    </div>
  );
}
