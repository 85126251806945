import { useEffect, useState } from "react";
import NewPasswordBox from "../components/auth-component/newPasswordBox";

export default function NewPassword() {
  const images = [
    "bg-login-1-image ",
    "bg-login-2-image ",
    "bg-login-3-image ",
  ];
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(images[Math.floor(Math.random() * (2 - 0 + 1)) + 0]);
  }, []);

  return (
    <div className={image + "  h-screen bg-cover overflow-hidden"}>
      <div className="absolute w-full h-full top-0 left-0 z-20  bg-black opacity-40"></div>
      <div className="z-30 absolute left-0 right-0 bottom-0 top-0">
        <div className="flex flex-row w-full justify-center h-full items-center">
          <NewPasswordBox />
        </div>
      </div>
    </div>
  );
}
