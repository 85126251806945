import React from "react";
import { Link } from "react-router-dom";
const Footer = (props) => {
  return (
    <footer
      className={props.color + " text-center lg:text-left w-screen mx-auto"}
    >
      <div className="text-white flex flex-row mx-auto max-w-7xl items-center justify-between py-6 w-auto px-6">
        <div className="flex flex-row  md:text-sm text-xs  text-end w-1/3">
          <Link to="/TermsAndConditions">
            <p>الشروط والأحكام</p>
          </Link>
        </div>
        <div className="md:flex row text-xs w-1/3 text-center justify-center">
          <p className="text-center  md:visible">جميع الحقوق محفوظة </p>
          <p className="mx-1">{new Date().getFullYear()} ©</p>
        </div>
        <div className="flex flex-row justify-end w-1/3 items-center">
          <a href="http://instagram.com/_u/hrrir.sa/" target="_blank">
            <i className="fa fa-instagram me-4 text-xl" />
          </a>
          <a href="mailto:Hi@Hrrir.com">
            <i className="fa fa-envelope-o  text-xl md:hidden block" />
            <p className="hidden md:block md:text-sm text-xs ">Hi@Hrrir.com</p>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
