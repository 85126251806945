import axios from "axios";
import * as actions from "../apiActions";
import { push } from "connected-react-router";
import { refreshToken } from "../auth";

export const baseUrl = process.env.REACT_APP_HOST_ENV === "production" ?
    "https://api.hrrir.com/" : "https://harer-api.herokuapp.com/";

const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type != actions.apiCallBegan) return next(action);

    const { url, method, data, onStart, onSuccess, onError, params, path } =
      action.payload;

    if (onStart) dispatch({ type: onStart });

    next(action);
    createAxiosInterceptor(dispatch);
    try {
      const response = await axios.request({
        baseURL: baseUrl,
        url,
        method,
        headers: {
          Authorization:
            typeof getState().entities.currentUser.data.accessToken ==
            "undefined"
              ? ""
              : getState().entities.currentUser.data.accessToken,
          "Accept-Language": "ar",
        },
        data,
        params,
      });

      if (onSuccess) {
        dispatch({
          type: onSuccess,
          payload: response.data,
        });
        if (path != null && typeof path != "undefined") dispatch(push(path));
      }
    } catch (error) {
      if (onError)
        dispatch({
          type: onError,
          payload: error.response.data,
        });
    }
  };

export default api;

export function createAxiosInterceptor(dispatch) {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axios.interceptors.response.eject(interceptor);
      return axios
        .request({
          baseURL: baseUrl,
          url: "/v1/student/refresh-token",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          method: "get",
        })
        .then((response) => {
          dispatch(refreshToken(response.data));
          localStorage.setItem("token", response.data.refreshToken);
          error.response.config.headers["Authorization"] =
            response.data.accessToken;
          return axios(error.response.config);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.setItem("isAuth", false);
            dispatch(push("/login"));
            return Promise.reject(error);
          }
        });
    }
  );
}
