import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Navbar from "../components/navbar";

import { loadProfile } from "../store/profile";
import ProfileBox from "../components/profile/profileBox";
import LoadingIndicator from "../components/Loaderes/loadingIndicator";

export default function ProfilePage() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.entities.profile);

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  return (
    <div className="bg-primary min-h-screen w-screen text-white">
      <Navbar />

      <main>
        <div className="mx-auto max-w-6xl py-6 sm:px-6  lg:px-8 bg-primary min-h-screen">
          <p className="text-3xl font-bold mb-6 mt-2 mx-4 md:mx-0  text-center md:text-end">
            بيانات الملف الشخصي
          </p>
          <div className="flex flex-col flex-grow md:flex-row md:justify-center h-full mx-4 md:mx-0">
            {profile.loading ? (
              <LoadingIndicator />
            ) : (
              <div className="flex flex-col w-full bg-secondary p-4">
                <ProfileBox />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
