// waitingList.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "waitingList",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    errorMessage: "",
  },
  reducers: {
    waitingListRequested: (waitingList, action) => {
      waitingList.loading = true;
    },
    waitingListRequestedFailed: (waitingList, action) => {
      waitingList.loading = false;
      waitingList.errorMessage = action.payload;
    },
    waitingListAdded: (waitingList, action) => {
      waitingList.data = action.payload;
      waitingList.loading = false;
      waitingList.lastFetch = Date.now();
      window.location.reload();
    },
  },
});

// Actions
const { waitingListRequested, waitingListRequestedFailed, waitingListAdded } =
  slice.actions;

export default slice.reducer;

const url = "/v1/waiting-list/register";

export const addWaitingList = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      data,
      url,
      onStart: waitingListRequested.type,
      onSuccess: waitingListAdded.type,
      onError: waitingListRequestedFailed.type,
      method: "post",
    })
  );
};
