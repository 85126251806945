// classes.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "classes",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
  },
  reducers: {
    classesRequested: (classes, action) => {
      classes.loading = true;
    },
    classesRequestFailed: (classes, action) => {
      classes.loading = false;
      classes.isFailed = true;
      classes.errorMessage = action.payload;
    },
    classesRequestSuccess: (classes, action) => {
      classes.isFailed = false;
      classes.loading = false;
      classes.errorMessage = "";
      classes.data = action.payload;
      classes.lastFetch = Date.now();
    },
  },
});

// Actions
export const { classesRequested, classesRequestFailed, classesRequestSuccess } =
  slice.actions;

export default slice.reducer;

const resourcesUrl = "/v1/student/classes";
// Action Creators
export const loadClasses = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: resourcesUrl,
      onStart: classesRequested.type,
      onSuccess: classesRequestSuccess.type,
      onError: classesRequestFailed.type,
    })
  );
};
