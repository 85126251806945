import Checkout from "./Pages/checkout";
import SignUp from "./Pages/Signup";
import Login from "./Pages/login";
import ResetPassword from "./Pages/resetPassword";
import NewPassword from "./Pages/newPassword";
import UserDashboard from "./Pages/userDashboard";
import VideosPage from "./Pages/videoPage";
import Payment from "./Pages/payment";
import LandingPageWithVideo from "./Pages/LandingPageWithVideo";
import PaymentSuccess from "./Pages/paymentSuccess";
import PaymentError from "./Pages/paymentError";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ProfilePage from "./Pages/profile";

const routes = [
  { path: "/signup", component: SignUp, isSecure: false },
  { path: "/:id/videos/:vid", component: VideosPage, isSecure: true },
  { path: "/login", component: Login },
  { path: "/resetPassword", component: ResetPassword, isSecure: false },
  { path: "/newPassword/:id", component: NewPassword, isSecure: false },
  { path: "/dashboard", component: UserDashboard, isSecure: true },
  { path: "/payment", component: Payment, isSecure: true },
  { path: "/checkout", component: Checkout, isSecure: true },
  { path: "/payment-success", component: PaymentSuccess, isSecure: false },
  {
    path: "/TermsAndConditions",
    component: TermsAndConditions,
    isSecure: false,
  },
  { path: "/profile", component: ProfilePage, isSecure: true },
  { path: "/payment-error", component: PaymentError, isSecure: false },
  { path: "/", component: LandingPageWithVideo, isSecure: false },
];

export const isHome = (value) => {
  return value === "/" || !routes.map((route) => route.path).includes(value);
};
export default routes;
