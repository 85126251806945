import { motion } from "framer-motion/dist/framer-motion";
import AnimatedImage from "./animatedImage";

const CoachSection = () => {
  return (
    <div className="bg-black w-full text-white text-center overflow-hidden h-400 md:block hidden ">
      <div className="mx-auto max-w-7xl md:px-20 sm:px-6 p-8 lg:px-8 bg-black relative h-full ">
        <div className="absolute w-full h-full top-0 left-0 z-20   border-black opacity-90  filter blur-xl borderNoTop-52"></div>
        <div className="flex flex-col md:space-y-2 space-y-2 w-full mb-6 items-center  h-full md:justify-center justify-start">
          <motion.div
            viewport={{ once: true }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.25 }}
            initial={{ opacity: 0 }}
            className="flex flex-col px-8 pt-2 pb-4  rounded-bl-large  rounded-t-lg rounded-br-lg bg-secondary  z-30 w-3/5"
          >
            <motion.img
              viewport={{ once: true }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.25 }}
              initial={{ opacity: 0 }}
              src="assets/images/moh.png"
              className="w-48 h-52 z-50 absolute -ms-40 -mt-16 "
            />

            <br />
            <p className="font-bold text-2xl mb-2 text-right  ms-12">
              محمد الحقباني
            </p>
            <p className="md:text-base text-xs ms-10">
              صانع محتوى ومحرر فيديو احترافي، مارس هذه المهنة على مدار السنوات
              الخمس الماضية، وعلى الرغم من احترافيته في العمل، أمضى معظم وقته في
              البحث عن التطوير المستمر والارتقاء نحو القمة في الإبداع، وكان شغفه
              هو الدافع الأكبر لعكس إبداعه على الأعمال التجارية ومنه الانتقال
              للعمل على الأفلام الوثائقية وصولًا إلى الفيديوهات الفنية الموسيقية
              والتغطيات باختلاف أنواعها. <br />
              إذ أنه ارتكز بكل ذلك على التميّز والسرد القصصي في كل ما يُنتجه.
            </p>
          </motion.div>
        </div>

        <AnimatedImage
          rotation={0}
          src="assets/images/type.png"
          class="absolute top-0 md:top-16 left-16 filter blur-md"
          dim="md:w-32 md:h-32 w-14 h-14"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrow.png"
          class="absolute   md:top-0  bottom-0 filter blur-md"
          dim="md:w-40 md:h-40 w-24 h-24"
        />

        <AnimatedImage
          rotation={0}
          src="assets/images/arrow3.png"
          class="absolute  md:right-56  md:bottom-10  left-2/4 bottom-1/4 filter blur-ms "
          dim="md:w-20 md:h-20 w-10 h-10 "
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/flash.png"
          class=" absolute md:top-0  md:left-3/4 left-1/4  bottom-0  md:mb-11 ml-4 filter blur-md"
          dim="md:w-24 md:h-24 h-12 w-12"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/invertedarrow.png"
          class="absolute top-0 right-1/4 ml-4 filter blur-ms"
          dim="md:w-24 md:h-24 w-12 h-12"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/hand.png"
          class="  absolute bottom-1/4 right-0  md:right-3/4 md:left-0  md:top-0  l filter blur-md"
          dim="md:w-28 md:h-28 w-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrowcut.png"
          class="absolute   top-0 left-1/3  filter blur-xs"
          dim="md:w-24 md:h-24 w-16 h-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/inverted.png"
          class="absolute   md:right-2/4  md:bottom-2 right-0 bottom-3/4 filter blur-xs"
          dim="md:w-24 md:h-24  w-12 h-12"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/arrowone.png"
          class="absolute  md:right-0  right-1/3 bottom-3/4 md:bottom-4  filter blur-md"
          dim="md:w-36 md:h-36 h-16 w-16"
        />
        <AnimatedImage
          rotation={0}
          src="assets/images/select.png"
          class="absolute  md:right-3/4  md:bottom-0  left-0 bottom-8 filter blur-ms"
          dim="md:w-24 md:h-24 w-12 h-12"
        />
      </div>
    </div>
  );
};

export default CoachSection;
// h-screen items-center

/***
 * 
 *           <motion.div
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            initial={{ opacity: 0 }}
            className="flex flex-col px-8 pt-2 pb-4  rounded-bl-large  rounded-t-lg rounded-br-lg bg-secondary  z-0 w-3/5"
          >
            <motion.img
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              initial={{ opacity: 0 }}
              src="assets/images/moh.png"
              className="w-48 h-52 z-50 absolute -ms-40 -mt-16"
            />

            <p className=" ms-12">المدرب</p>
            <p className="font-bold text-2xl mb-2 text-right  ms-12">
              محمد الحقباني
            </p>
            <p className="md:text-base text-xs ms-10">
              هو محرر فيديو وصانع محتوى. على مدار السنوات الخمس الماضية, أمضى
              معظم وقته في تحسين أسلوبه, والبحث دائما عن الإبداع. سمح له شغفه
              بتحرير الفيديو إلى نقل إبداعه للأعمال التجارية والعمل على
              وثائقيات,فيديوات موسيقية. وتغطيات إبداعية. فإن سرد القصص هو في
              صميم كل مايبتكره.
            </p>
          </motion.div>
 */
