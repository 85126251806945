import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "./reducers";
import api from "./middleware/api";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export default function () {
  return configureStore({
    devTools: process.env.REACT_APP_HOST_ENV !== "production",
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(routerMiddleware(history))
        .concat(api),
  });
}
