import React from "react";

const SuccessAlert = (props) => {
  return (
    <div className=" bg-alert-success-color  text-text-success p-1 w-full md:w-80 text-sm text-center">
      {props.message}{" "}
    </div>
  );
};

export default SuccessAlert;
