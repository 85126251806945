import { Link } from "react-router-dom";

const Card = (props) => {
  const destination = "/" + props.index + "/videos/0";
  const isLink = props.href !== undefined;

  const courseCompletionPercentage = function () {
    const numberOfWatchedVideos = props.lessons.filter(
      (lesson) => lesson.watched
    ).length;
    const totalNumberOfVideos = props.lessons.length;
    const percentage =
      numberOfWatchedVideos === 0
        ? 0
        : numberOfWatchedVideos / totalNumberOfVideos;
    return percentage * 100;
  };

  return (
    <div className="max-w-xs rounded overflow-hidden shadow-sm">
      {isLink ? (
        <a href={props.href} target="_blank">
          <img className="w-full " src={props.url} alt="image" />
        </a>
      ) : (
        <div>
          {props.lessons.length > 0 ? (
            <Link to={destination}>
              <img className="w-full " src={props.url} alt="image" />
            </Link>
          ) : (
            <a
              href=""
              onClick={(e) => {
                props.toggle();
                e.preventDefault();
              }}
            >
              <img className="w-full " src={props.url} alt="image" />
            </a>
          )}
        </div>
      )}
      {isLink ? (
        <hr
          className="border-4 border-text-success animate-pulse"
          style={{ width: "100%" }}
        />
      ) : (
        <hr
          className="border-4 border-primary-blue-color"
          style={{ width: `${courseCompletionPercentage()}%` }}
        />
      )}

      <div className="px-6 py-4  bg-secondary mb-4 h-full">
        <div className=" mb-2 text-center text-white">
          <p className="font-bold text-xl"> {props.title}</p>
          <p className="text-xs my-2">
            {" "}
            {isLink
              ? "رابط خارجي"
              : ` أكملت ${
                  props.lessons.filter((lesson) => lesson.watched).length
                } مقاطع من ${props.lessons.length}`}
          </p>
          <hr className="border-1 border-gray-600" />
        </div>
        <p className="text-white text-sm text-right whitespace-pre-line font-tajawal">
          {props.details}
        </p>
      </div>
    </div>
  );
};

export default Card;
