import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemsList from "./itemsList";
import { applyCoupon } from "../../store/cart";
import OutlineButton from "../buttons/outlineButton";

const Basket = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.entities.cart);

  const [coupon, setCoupon] = useState("");

  return (
    <div className="flex flex-col  md:w-3/5 mx-4">
      <p className="text-white my-4"> سلة المشتريات</p>
      <div>
        <ItemsList />
      </div>
      <div>
        <hr className="border-1 border-gray-600 " />
      </div>
      <div className="flex flex-row py-4  bg-secondary px-4">
        <div className="flex items-center w-2/5 me-4">
          <input
            className="bg-custom-g py-2 ps-2 text-white rounded-sm w-full border-custom-g"
            type="text"
            placeholder="الكود الترويجي"
            onChange={(evt) => setCoupon(evt.target.value)}
          />
        </div>

        <div className="flex items-center h-full w-1/2">
          <OutlineButton
            title="تطبيق الكود"
            action={() => {
              if (coupon.length > 0) dispatch(applyCoupon(coupon));
            }}
            isLoading={cart.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Basket;
