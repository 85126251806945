import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PrimaryButton from "../buttons/primaryButton";
import { applyCoupon } from "../../store/cart";
import { useHistory } from "react-router-dom";

const TotalAmount = () => {
  let history = useHistory();
  const cart = useSelector((state) => state.entities.cart);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col md:w-2/5  mx-4">
      <p className="text-white my-4">مجموع السلة</p>
      <div>
        {/* TOTAL Amount without discount */}
        <div className="flex flex-col rounded-sm bg-secondary ">
          <div className="flex flex-row justify-between  py-2">
            <p className="text-white p-4"> مجموع المنتجات</p>
            <p className="text-white p-4">
              {typeof cart.data.totals == "undefined"
                ? "--"
                : cart.data.totals.totalBeforeDiscountAmount}{" "}
              ريال
            </p>
          </div>
          <hr className="border-1 border-gray-600" />

          {typeof cart.data.coupon == "undefined" ||
          cart.data.coupon == null ? (
            <div></div>
          ) : (
            <div className="flex flex-row justify-between  py-2">
              <p className="text-white p-4">
                {" "}
                الرمز الترويجي: {cart.data.coupon.code}
              </p>
              <p className="text-red-700 p-4">
                <a
                  className="px-1"
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(applyCoupon(null));
                  }}
                  href=""
                >
                  [<u>حذف</u>]
                </a>
                {cart.data.totals.totalDiscount} ريال
              </p>
            </div>
          )}

          <hr className="border-1 border-gray-600" />

          <div className="flex flex-row justify-between py-2">
            <p className="text-primary-blue-color p-4">الإجمالي</p>
            <p className="text-primary-blue-color p-4">
              {typeof cart.data.totals == "undefined"
                ? "--"
                : cart.data.totals.totalAmount}{" "}
              ريال
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between py-2 my-4">
        <PrimaryButton
          title="المواصلة للدفع"
          isLoading={false}
          action={() => history.push("/payment")}
        />
      </div>
    </div>
  );
};

export default TotalAmount;
