import { useDispatch } from "react-redux";
import { logout } from "../store/auth";
import { useHistory, Link } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const isAuth = localStorage.getItem("token");
  const dispatch = useDispatch();
  return (
    <nav className="bg-primary shadow-sm sticky top-0 w-screen z-50">
      <div className="max-w-6xl mx-auto lg:px-12 md:px-10 px-6 ">
        <div className="flex justify-between items-center">
          <div className="flex space-x-7">
            <div>
              <Link to="/">
                <img
                  className=" w-12 h-12 my-4"
                  src="../../assets/images/hrrir-white.png"
                />
              </Link>
            </div>
          </div>
          <div>
            {isAuth == null ? (
              <Link to="/login">
                <button
                  type="button"
                  className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 "
                >
                  تسجيل الدخول
                </button>
              </Link>
            ) : (
              <div className="space-">
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 "
                  >
                    لوحة التحكم
                  </button>
                </Link>
                <button
                  onClick={() => {
                    history.push("/profile");
                  }}
                  type="button"
                  className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 ms-2 "
                >
                  <i className="fa fa-solid fa-user"></i>
                </button>

                <button
                  onClick={() => {
                    dispatch(logout());
                    history.push("/");
                  }}
                  type="button"
                  className="transition duration-300 ease-in-out bg-none hover:bg-white hover:text-black  border-solid border rounded text-white px-2 py-1 ms-2 "
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="border-1 border-gray-600" />
    </nav>
  );
};

export default Navbar;
