import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addWaitingList } from "../../store/waitinglist";
import { inputDecorator } from "../../utils/styleHelper";
import ErrorAlert from "../Loaderes/errorAlert";

const WaitingListPopupDialog = (props) => {
  const dispatch = useDispatch();
  const waiting = useSelector((state) => state.entities.waitingList);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    dispatch(
      addWaitingList({
        fullName: data.name,
        email: data.email,
      })
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="fixed z-50 inset-0 overflow-y-auto "
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex md:items-end items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-secondary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-secondary px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex  md:justify-center h-12 md:w-12 rounded-full bg-secondary sm:mx-0 sm:h-10 sm:w-10 w-full justify-between">
                  <div></div>
                  <svg
                    className="h-6 w-6 filter text-white fill-current"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 310 310"
                    space="preserve"
                  >
                    <path
                      d="M300.606,159.727l-45.333-45.333c-5.857-5.858-15.355-5.858-21.213,0L225,123.454V15c0-8.284-6.716-15-15-15H20
	C11.716,0,5,6.716,5,15v240.002c0,8.284,6.716,15,15,15h85V295c0,8.284,6.716,15,15,15h45.333c3.979,0,7.794-1.581,10.607-4.394
	l124.667-124.667C306.465,175.081,306.465,165.585,300.606,159.727z M35,30h160v123.454l-85.606,85.605
	c-0.302,0.301-0.581,0.619-0.854,0.942H35V30z M159.12,280H135v-24.121l109.667-109.666l24.12,24.12L159.12,280z"
                    />
                  </svg>
                  <a
                    className="md:hidden block"
                    href=""
                    onClick={(e) => {
                      props.toggle();
                      e.preventDefault();
                    }}
                  >
                    <i
                      className="fa fa-times text-white text-lg"
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <div className="mt-3 md:ms-4 ms-0 sm:mt-0 sm:ml-4 md:text-right ">
                  <div className="flex md:flex-row flex-col md:justify-between">
                    <h3
                      className="text-lg leading-6 font-medium text-white md:text-end text-center"
                      id="modal-title"
                    >
                      التسجيل في قائمة الانتظار
                    </h3>
                    <a
                      className="md:block hidden"
                      href=""
                      onClick={(e) => {
                        props.toggle();
                        e.preventDefault();
                      }}
                    >
                      <i
                        className="fa fa-times text-white text-lg"
                        aria-hidden="true"
                      />
                    </a>
                  </div>

                  <p className="text-white md:text-end text-center my-2 text-sm">
                    سيتم إبلاغكم برسالة في البريد الالكتروني فور بدء التسجيل
                    للرحلة الجديدة
                  </p>
                  {typeof waiting.errorMessage.title == "undefined" ? (
                    <div className="bg-secondary"></div>
                  ) : (
                    <ErrorAlert message={waiting.errorMessage.detail} />
                  )}
                  <div className="mt-2 flex md:flex-row flex-col">
                    <input
                      className={
                        typeof errors.name == "undefined"
                          ? inputDecorator(false)
                          : inputDecorator(true)
                      }
                      type="text"
                      placeholder="الاسم"
                      {...register("name", { required: true })}
                    />
                    <input
                      className={
                        typeof errors.email == "undefined"
                          ? inputDecorator(false)
                          : inputDecorator(true)
                      }
                      type="email"
                      placeholder="البريد الإلكتروني"
                      {...register("email", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-secondary px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-blue-color text-base font-medium text-white hover:bg-primary-blue-color focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                التسجيل في قائمة الانتظار
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WaitingListPopupDialog;
