import React, { useEffect } from "react";
import ErrorAlert from "../Loaderes/errorAlert";
import SuccessAlert from "../Loaderes/successAlert";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswords, resetState } from "../../store/auth";
import PrimaryButton from "../buttons/primaryButton";
import { inputDecorator } from "../../utils/styleHelper";
import { Link } from "react-router-dom";

const ResetPasswordBox = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch({ type: resetState.type });
  }, []);
  const onSubmit = (data) => {
    dispatch(forgotPasswords(data.email));
  };

  const auth = useSelector((state) => state.entities.currentUser);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col bg-secondary py-8   md:px-20 px-8 md:mt-6 md:mb-0 mb-16 text-center mx-4 rounded-sm items-center">
        <div>
          <Link to="/">
            <img className="p-4 w-28 h-24" src="assets/images/hrrir.png" />
          </Link>
        </div>
        <p className="text-white mb-8 w-full md:w-80 mt-6 text-sm">
          <strong> نسيت كلمة المرور؟ </strong>
          <br />
          لا عليك أدخل البريد الإلكتروني و سيصلك رابط لإنشاء كلمة مرور جديدة
        </p>
        {typeof auth.errorMessage.title == "undefined" ? (
          <div className="bg-secondary"></div>
        ) : (
          <ErrorAlert message={auth.errorMessage.detail} />
        )}
        {auth.SuccessMessage === "" ? (
          <div className="bg-secondary"></div>
        ) : (
          <SuccessAlert message={auth.SuccessMessage} />
        )}
        <div className="w-full">
          <input
            className={
              typeof errors.email == "undefined"
                ? inputDecorator(false)
                : inputDecorator(true)
            }
            type="email"
            placeholder="البريد الإلكتروني"
            {...register("email", { required: true })}
          />
        </div>

        <div className="mt-4 mb-6 w-full">
          <PrimaryButton title="إستعادة كلمة المرور" isLoading={auth.loading} />
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordBox;
