import Navbar from "../components/navbar";
import { useEffect } from "react";
import VideoListBox from "../components/videoPage-component/videoListBox";
import Vimeo from "@u-wave/react-vimeo";
import { useParams, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { loadResources, markVideoAsWatched } from "../store/resources";
import { Anchorme } from "react-anchorme";

export default function VideosPage() {
  const { id } = useParams();
  let { vid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCourse = useSelector((state) => state.entities.resources.data);
  const nextDestination = "/" + id + "/videos/" + (parseInt(vid) + 1);
  const previousDestination = "/" + id + "/videos/" + (parseInt(vid) - 1);

  useEffect(() => {
    dispatch(loadResources());
  }, [dispatch]);


  return (
    <div className="bg-primary w-screen min-h-screen overflow-hidden ">
      <Navbar />
      {typeof currentCourse.courses != "undefined" ? (
        <main>
          <div className="mx-auto max-w-6xl py-6 sm:px-6  lg:px-8 bg-primary min-h-screen">
            <div className="flex flex-col justify-center">
              <p className="text-4xl text-white font-bold my-5 md:w-1/3 w-full md:mx-4 mx-0 md:text-right text-center">
                {currentCourse.courses[id].title}
              </p>
              <div className="md:w-2/3 w-0" />
            </div>

            <div className="flex flex-col flex-grow md:flex-row md:justify-center min:h-screen bg-primary">
              <div className="md:w-1/3 w-full md:order-none order-1 ">
                <VideoListBox />
              </div>
              <div className="flex flex-col md:mx-4 mx-8 my-6 md:my-0 md:w-2/3 ">
                <div className="w-full ">
                  <Vimeo
                    className="z-0"
                    responsive={true}
                    video={currentCourse.courses[id].lessons[vid].video}
                    onEnd={() => {
                      dispatch(
                        markVideoAsWatched(
                          {
                            courseId: currentCourse.courses[id].id,
                            lessonId: currentCourse.courses[id].lessons[vid].id,
                          },
                          currentCourse.courses[id].lessons.length - 1 != vid
                            ? nextDestination
                            : null
                        )
                      );
                    }}
                  />
                  <p className="text-white my-4 text-xl">
                    {currentCourse.courses[id].lessons[vid].title}{" "}
                  </p>
                  <div className="w-full bg-secondary p-4 mb-8  whitespace-pre-line text-white">
                    <Anchorme
                      className="text-primary-blue-color"
                      target="_blank"
                    >
                      {currentCourse.courses[id].lessons[vid].description}
                    </Anchorme>
                  </div>
                  <div className="flex flex-row w-full md:justify-end justify-center mb-2">
                    {vid != 0 && (
                      <button
                        className="text-primary-blue-color transition duration-500 ease-in-out "
                        onClick={() => {
                          history.push(previousDestination);
                        }}
                      >
                        <i
                          className="fa fa-long-arrow-right ps-2"
                          aria-hidden="true"
                        />{" "}
                        السابق
                      </button>
                    )}

                    {currentCourse.courses[id].lessons.length - 1 != vid && (
                      <button
                        className="bg-primary-blue-color text-white px-6 py-2 mx-4 rounded-md "
                        onClick={() => {
                          history.push(nextDestination);
                        }}
                      >
                        الدرس التالي
                        <i
                          className="fa fa-long-arrow-left ps-2"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <div />
      )}
    </div>
  );
}
