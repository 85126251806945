//Cart.js
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiActions";

// Reducer
const slice = createSlice({
  name: "cart",
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
    isFailed: false,
    errorMessage: "",
  },
  reducers: {
    cartRequested: (cart, action) => {
      cart.loading = true;
    },
    cartRequestFailed: (cart, action) => {
      cart.loading = false;
      cart.isFailed = true;
      cart.errorMessage = action.payload;
    },
    cartAdded: (cart, action) => {
      cart.isFailed = false;
      cart.errorMessage = "";
      cart.data = action.payload;
      cart.loading = false;
      cart.lastFetch = Date.now();
    },

    productDelete: (cart, action) => {
      cart.data.products.filter((product) => product.id !== action.payload.id);
    },

    couponDelete: (cart, action) => {
      cart.data.coupon = null;
    },
  },
});

// Actions
export const {
  cartRequested,
  cartAdded,
  cartRequestFailed,
  productDelete,
  couponDelete,
} = slice.actions;

export default slice.reducer;

const url = "/v1/cart";

// Action Creators
export const loadCart = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      onStart: cartRequested.type,
      onSuccess: cartAdded.type,
      onError: cartRequestFailed.type,
    })
  );
};

export const applyCoupon = (coupon) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      method: "patch",
      data: { coupon: coupon },
      onStart: cartRequested.type,
      onSuccess: cartAdded.type,
      onError: cartRequestFailed.type,
    })
  );
};
